// import Moment from 'moment';

import { isNullOrWhitespace } from "./text-helpers";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

export function deepCopy(data) {
  const replacer = (key, value) => {
    return typeof value === 'undefined' ? null : value;
  }
  if (!data) return data;
  return JSON.parse(JSON.stringify(data, replacer));
}

// export function formatTime(time) {
//   const duration: any = Moment.duration(time, 'seconds');
//   return `${duration._data.hours}H ${duration._data.minutes}M ${duration._data.seconds}S`;
// }


export function createUUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
  });
  return uuid;
}

export function getQueryParams(decodeUrl = true): any {
  let match,
    pl = /\+/g,  // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
    query = (window.location.href.split('?')[1] || '').split('&');
  const urlParams: object = {};

  query.forEach(element => {
    if (!isNullOrWhitespace(element)) {
      const pair = element.split('=');
      urlParams[decode(pair[0])] = decodeUrl ? decode(pair[1]) : pair[1];
    }
  });
  return urlParams;
}

export const onlyTheseKeys = (object, keys: string[]) => {
  return Object.keys(object).reduce((removedKeysObject, key) => {
    if (keys.indexOf(key) === -1) {
      delete removedKeysObject[key];
    }

    return removedKeysObject;
  }, { ...object });
};

export const removeTheseKeys = (object, keys: string[]) => {
  return Object.keys(object).reduce((removedKeysObject, key) => {
    if (keys.indexOf(key) > -1) {
      delete removedKeysObject[key];
    }

    return removedKeysObject;
  }, { ...object });
};

export const removeNulls = (object) => {
  return Object.keys(object).reduce((noNullsObject, key) => {
    if (noNullsObject[key] === null) {
      delete noNullsObject[key];
    }

    return noNullsObject;
  }, { ...object });
};

export function extendObject(destination, source) {
  Object.keys(source).forEach(key => {
    destination[key] = deepCopy(source[key]);
  });
}

export function checkObjectArrayPropHasValues(items: any[], arrayPropName: string) {
  let result: boolean = false;
  if (!!items) {
    items.forEach(x => {
      if (!!x[arrayPropName] && x[arrayPropName].length > 0) {
        result = true
        return result;
      }
    });
  }
  return result;
}

export function moveInArray(arr: any[], old_index: number, new_index: number) {
  var element = arr[old_index];
  arr.splice(old_index, 1);
  arr.splice(new_index, 0, element);
}

export function svgToURL(s) {
  const uri = window.btoa(s);
  return "data:image/svg+xml;base64," + uri;
}

export function hasDuplicateKeyValues<T>(values: T[], propertyName: string): boolean {
  const valueArr = values.map(item => item[propertyName]);
  return valueArr.some((item, idx) => valueArr.indexOf(item) != idx);
}


export function pushEvent(event: string, additionalProperties: {[key: string]: string | number}) {
  window.dataLayer.push({
    event,
    ...additionalProperties
  })
}