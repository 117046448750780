import styled from 'styled-components';
import BREAKPOINTS from '../../../config/breakpoints';

const cellGutter = '1.5rem';
const numberOfCols = 12;

interface ColumnProps {
    size: number;
    tablet?: number;
    mobile?: number;
    center?: boolean;
    debug?: boolean;
    noMarginBottom?: boolean;
    marginTop?: number;
    marginBottom?: number;
    hasBorder?: boolean;
    textRight?: boolean;
    textCenter?: boolean;
    checkboxInput?: boolean;
    radioInput?: boolean;
}

interface RowProps {
    hidden?: boolean;
    reverseMobile?: boolean;
    hiddenMobile?: boolean;
    hiddenTablet?: boolean;
    hiddenDesktop?: boolean;
    children?: React.ReactNode;
    className?: string;
}

function columnSize(size: number): string {
    return ((100 / numberOfCols) * size).toString()
}

export const Row = styled.div<RowProps>`
    display: flex;
    clear:both;
    margin-left: -${cellGutter};
    flex-wrap: wrap;
    position: relative;
    content: '${props => props.reverseMobile?.toString() + ' '}';
    ${props => props.hidden && 'display: none;'}

    ${props => props.reverseMobile ? `
        @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
            flex-direction: column-reverse;
        }
    ` : ''}

    ${props => props.hiddenMobile ? `
        @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
            display: none;
        }
    ` : ''}

    ${props => props.hiddenTablet ? `
        @media (min-width: ${BREAKPOINTS.mobileLarge}px) and (max-width: ${BREAKPOINTS.desktopLarge}px) {
            display: none;
        }
    ` : ''}

    ${props => props.hiddenDesktop ? `
        @media (min-width: ${BREAKPOINTS.desktopLarge}px) {
            display: none;
        }
    ` : ''}

    & {
        width: 100%;
    }
`

const Scroller = styled.div`
    overflow-x: auto;
    width: 100%;

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        ${Row} {
            width: max-content; 
        }
    }
`

export const ScrollRow = (props: RowProps) => {
    return (
        <Scroller>
            <Row {...props}>
                {props.children}
            </Row>
        </Scroller>
    )
}

export const Column = styled.div<ColumnProps>`
    display: block;
    float: left;
    min-height: 1rem;
    width: calc(${props => columnSize(props.size)}% - ${props => props.size != 12 ? cellGutter : '0px'});
    max-width: calc(${props => columnSize(props.size)}% - ${props => props.size != 12 ? cellGutter : '0px'});
    min-width: calc(${props => columnSize(props.size)}% - ${props => props.size != 12 ? cellGutter : '0px'});
    margin-top: ${props => props.marginTop ? `${props.marginTop}px` : '0'};
    margin-left: ${cellGutter};
    ${props => props.center && 'margin: 0 auto;'}
    ${props => props.hasBorder && `
        border: 1px solid ${props.theme.borderColor};
        border-radius: 5px;
        padding: 0.5rem;
    `}
    margin-bottom: ${props => props.noMarginBottom ? '0' : (props => props.marginBottom ? `${props.marginBottom}px` : `${cellGutter}`)};
    ${props => props.debug ? 'background-color: grey;' : ''}
    ${props => props.textRight? 'text-align: right;' : ''}
    ${props => props.textCenter? 'text-align: center;' : ''}
    ${props => props.checkboxInput && 'padding-top: 2.3rem;'}
    ${props => props.radioInput && 'padding-top: 1.7rem;'}

    @media (max-width: ${BREAKPOINTS.desktop}px) {
        width: calc(${props => columnSize(props.tablet)}% - ${props => props.tablet != 12 ? cellGutter : '0px'});
        max-width: calc(${props => columnSize(props.tablet)}% - ${props => props.tablet != 12 ? cellGutter : '0px'});
        min-width: calc(${props => columnSize(props.tablet)}% - ${props => props.tablet != 12 ? cellGutter : '0px'});
    }

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        width: calc(${props => columnSize(props.mobile)}% - ${props => props.mobile != 12 ? cellGutter : '0px'});
        max-width: calc(${props => columnSize(props.mobile)}% - ${props => props.mobile != 12 ? cellGutter : '0px'});
        min-width: calc(${props => columnSize(props.mobile)}% - ${props => props.mobile != 12 ? cellGutter : '0px'});
    }
`