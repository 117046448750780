import { Container } from '@chakra-ui/react';
import React from 'react';

interface ComponentProps {
    children: React.ReactNode;
    noMarginBottom?: boolean;
    className?: string;
}

const PageContainer = ({ children, noMarginBottom, className }: ComponentProps) => {
  return (
    <Container maxW='5xl' style={{marginBottom: noMarginBottom ? null : '6rem'}} className={className}>{children}</Container>
  );
};

export default PageContainer;