import styled from "styled-components";

interface HeadingsSettings {
    textCenter?: boolean;
    hideDivider?: boolean;
    theme?: any;
    marginBottom?: any;
}

const baseHeaderStyle = (props: HeadingsSettings) => `
        text-align: ${props.textCenter ? 'center' : 'left'};
        font-weight: 600;
        font-size: revert !important;
        margin-bottom: ${props.marginBottom || props.marginBottom == 0 ? props.marginBottom : 1}rem;
        padding: 0.5rem 0;
        color: ${props.theme.primary};
`

export const HeadingDivider = styled.div<{full?: boolean}>`
    border-top: 2px solid ${props => props.theme.primary};
    padding-bottom: 1rem;
    width: ${props => props.full ? '100%' : '30%'};
    margin: 0 auto;
`

interface HeadingProps extends HeadingsSettings {
    children: React.ReactNode;
}

export const H1 = ({children, hideDivider, textCenter = true}: HeadingProps) => {
    return (
        <>
            <H1style textCenter={textCenter}>{children}</H1style>
            {!hideDivider && <HeadingDivider full={!textCenter} />}
        </>
    )
}

export const H2 = ({children, hideDivider, textCenter = true}: HeadingProps) => {
    return (
        <>
            <H2style textCenter={textCenter}>{children}</H2style>
            {!hideDivider && <HeadingDivider full={!textCenter} />}
        </>
    )
}

export const H3 = ({children, hideDivider, marginBottom, textCenter = true}: HeadingProps) => {
    return (
        <>
            <H3style marginBottom={marginBottom} textCenter={textCenter}>{children}</H3style>
            {!hideDivider && <HeadingDivider full={!textCenter} />}
        </>
    )
}

export const H4 = ({children, hideDivider, marginBottom, textCenter = true}: HeadingProps) => {
    return (
        <>
            <H4style marginBottom={marginBottom} textCenter={textCenter}>{children}</H4style>
            {!hideDivider && <HeadingDivider full={!textCenter} />}
        </>
    )
}

export const H5 = ({children, hideDivider, textCenter = true}: HeadingProps) => {
    return (
        <>
            <H5style textCenter={textCenter}>{children}</H5style>
            {!hideDivider && <HeadingDivider full={!textCenter} />}
        </>
    )
}

export const H1style = styled.h1<HeadingsSettings>`
    ${props => baseHeaderStyle(props)}
`
export const H2style = styled.h2<HeadingsSettings>`
    ${props => baseHeaderStyle(props)}
`
export const H3style = styled.h3<HeadingsSettings>`
    ${props => baseHeaderStyle(props)}
`
export const H4style = styled.h4<HeadingsSettings>`
    ${props => baseHeaderStyle(props)}
`
export const H5style = styled.h5<HeadingsSettings>`
    ${props => baseHeaderStyle(props)}
`