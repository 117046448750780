import React from "react"
import { DropdownItem } from "../components/Forms/Dropdown";
import Icon from "../components/Media/Icon"
import { SpecialOccasion } from "../api/api-definitions";
import { isNullOrWhitespace } from "../utils/text-helpers";

export const Constants = {
    baseImageUrl: 'https://rossbelcher.blob.core.windows.net/forageruploads/',
    googleMapsApiKey: 'AIzaSyABBE4pcZ8u6Kz0nw3UaZ444UYpSTlxU2s',
    googleAuthApiKey: '980739392203-sum29nl5aov553mtr20odbbiptbgd6a0.apps.googleusercontent.com',
    stripeApiKey_LIVE: 'pk_live_51LNFgTFjddxmvDdLfTazsmTk6ydB1INfUHFMANeZzNfjbaQccSNy6VPIf1O5kgt6Ht0DV1Y7XHJ5q8NDXtZ4stjA00rVFI6hB6',
    stripeApiKey_TEST: 'pk_test_51LNFgTFjddxmvDdLJnpe3283Gx3g1NrQP7EHmcWyZi1QN5NfeUBxAgrvODmLh0Sa2tQGpOszBm4FZY2bMS0lkaAM00hdcOTO8k',
    businessName: 'Dish Forager',
    messaging: {
        makeSureToSave: 'Make sure to save your changes before navigating away'
    }
}

interface TagItemType {
    name: string;
    icon: string;
}

export const BaseDietTags: { [key: number]: TagItemType } = {
    1: { name: 'Vegan', icon: 'seedling' },
    2: { name: 'Vegetarian', icon: 'carrot' },
    3: { name: 'Pescatarian', icon: 'fish' },
    4: { name: 'Halal', icon: null },
    5: { name: 'Kosher', icon: null },
    6: { name: 'Organic', icon: 'leaf' },
    7: { name: 'Contains alcohol', icon: 'wine-bottle' },
    8: { name: 'Dairy free', icon: null },
    9: { name: 'Gluten free', icon: null }
}

// if you add any here, go to BaseDishTagLink.cs and amend IsAllergen property
export const BaseAllergenTags: { [key: number]: TagItemType } = {
    10: { name: 'Celery', icon: null },
    11: { name: 'Gluten', icon: null },
    12: { name: 'Crustaceans', icon: null },
    13: { name: 'Eggs', icon: null },
    14: { name: 'Fish', icon: null },
    15: { name: 'Lupin', icon: null },
    16: { name: 'Milk', icon: null },
    17: { name: 'Molluscs', icon: null },
    18: { name: 'Mustard', icon: null },
    19: { name: 'Nuts', icon: 'acorn' },
    20: { name: 'Peanuts', icon: null },
    21: { name: 'Sesame seeds', icon: null },
    22: { name: 'Soya', icon: null },
    23: { name: 'Sulphur dioxide', icon: null },
}

export const menuTypes: DropdownItem[] = [
    {
        text: 'À la carte',
        value: 'ALaCarte'
    }, {
        text: 'Afternoon tea',
        value: 'AfternoonTea'
    }, {
        text: 'Breakfast',
        value: 'Breakfast'
    }, {
        text: 'Brunch',
        value: 'Brunch'
    }, {
        text: 'Buffet',
        value: 'Buffet'
    }, {
        text: 'Christmas',
        value: 'Christmas'
    }, {
        text: 'Dairy free',
        value: 'DairyFree'
    }, {
        text: 'Desserts',
        value: 'Dessert'
    }, {
        text: 'Drinks',
        value: 'Drinks'
    }, {
        text: 'Easter',
        value: 'Easter'
    }, {
        text: 'Gluten free',
        value: 'GlutenFree'
    }, {
        text: 'Ice-cream',
        value: 'IceCream'
    }, {
        text: 'Kids menu',
        value: 'Childrens'
    }, {
        text: 'Lunch',
        value: 'Lunch'
    }, {
        text: 'Set menu / Fixed price',
        value: 'FixedPrice'
    }, {
        text: 'Specials',
        value: 'Specials'
    }, {
        text: 'Starters',
        value: 'Starters'
    }, {
        text: 'Tasting menu',
        value: 'TastingMenu'
    }, {
        text: 'Vegetarian menu',
        value: 'Vegetarian'
    }, {
        text: 'Vegan menu',
        value: 'Vegan'
    }, {
        text: 'Wine list',
        value: 'WineList'
    }, {
        text: 'Other',
        value: 'Other'
    }
];

export const DishNameSize: number = 255 // if this is changed, change type in database
export const DishDescriptionSize: number = 1000 // if this is changed, change type in database
export const MenuNameSize: number = 100 // if this is changed, change type in database
export const MenuDescriptionSize: number = 5000 // if this is changed, change type in database
export const MenuSectionNameSize: number = 100 // if this is changed, change type in database
export const MenuSectionDescriptionSize: number = 5000 // if this is changed, change type in database

export const ExperienceOrEventNameSize: number = 50 // if this is changed, change type in database

export function BookingSpecialOccasions(): { [key: string]: { label: string, icon: string } } {
    const returnValue: { [key: string]: { label: string, icon: string } } = {};
    for (let item in SpecialOccasion) {
        let icon = '';
        let description = '';
        switch (item) {
            case SpecialOccasion.Birthday:
                icon = 'cake';
                description = 'Birthday'
                break;
            case SpecialOccasion.Anniversary:
                icon = 'ring-diamond';
                description = 'Anniversary'
                break;
            case SpecialOccasion.DateNight:
                icon = 'heart';
                description = 'Date night'
                break;
            case SpecialOccasion.BusinessMeal:
                icon = 'handshake';
                description = 'Business meal'
                break;
            case SpecialOccasion.Graduation:
                icon = 'graduation-cap';
                description = 'Graduation'
                break;
            case SpecialOccasion.NewJob:
                icon = 'briefcase';
                description = 'New job'
                break;
            case SpecialOccasion.SpecialOccasion:
                icon = 'party-horn';
                description = 'Special occasion'
                break;
            default:
                break;
        }
        
        if (!isNullOrWhitespace(description)) {
            returnValue[item] = {
                label: description,
                icon
            }
        }
        
    }
    return returnValue;
}

export const BODY_FONT = "font-family: 'Inter', sans-serif !important;"
export const HEADING_FONT = "font-family: 'Inter', sans-serif !important;"

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TELEPHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;