import React from "react";
import { ErrorMessage } from "../Forms/Messaging";
import BugButton from "../../pages/BusinessDashboard/modules/Shared/bugButton";
import { SupportTicketService } from "../../services/SupportTicketService";
import { LoggingService } from "../../services/LoggingService";

interface State {
    hasError: boolean;
    error?: any;
}

interface Props {
    children: React.ReactNode;
    parentBusinessId?: number;
}

export default class WebErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, info) {
        LoggingService.Log(JSON.stringify({error, info}))
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <>
                <ErrorMessage>Sorry, the application has encountered en error and is unable to continue. Please refresh the page and try again. If the problem persists please contact us.</ErrorMessage>
            </>
        }

        return this.props.children;
    }
}