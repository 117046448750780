import { Elements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import PaymentService from '../../services/PaymentService';
import Loader from '../Layout/Loader';
import CheckoutForm from './CheckoutForm';
import { Constants } from '../../constants';
import { ThemeOptions } from '../../api/api-definitions';

interface Props {
    intentSecret: string;
    accountId?: string;
    payNow?: boolean;
    widget?: boolean;
    widgetTheme?: ThemeOptions;
    primaryContrast?: string;
    returnUrl?: string;
}

const Checkout = ({ intentSecret, widgetTheme, widget, returnUrl, accountId, payNow = false }: Props) => {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        let key = Constants.stripeApiKey_TEST;
        if (process.env.NODE_ENV === 'production') {
            key = Constants.stripeApiKey_LIVE;
        }
        PaymentService.InitPaymentService(key, accountId).then(() => {
            setLoaded(true)
        })
    }, [])

    return (
        <>
            {loaded && intentSecret &&
                <Elements stripe={PaymentService.App} options={{ clientSecret: intentSecret }}>
                    <CheckoutForm returnUrl={returnUrl} payNow={payNow} widgetTheme={widgetTheme} widget={widget} />
                </Elements>
            }
            {(!loaded || !intentSecret) &&
                <Loader />
            }
        </>
    );
};

export default Checkout;