import { IonApp, IonContent, IonPage, IonSkeletonText, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { light } from './theme';
import { ChakraProvider } from '@chakra-ui/react'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Suspense, lazy } from 'react';
// const BookingModule = lazy(() => lazyRetry(() => import('./bookingModule')));
import { RecoilEnv, RecoilRoot } from 'recoil';
import ThemeWrapper from './components/Theme/theme-wrapper';
import NotificationContainer from './components/Forms/Notifications/NotificationContainer';
import NotFound from './pages/NotFound';
import BREAKPOINTS from './config/breakpoints';
import WebErrorBoundary from './components/Boundries/WebErrorBoundry';
import BookingModule from './bookingModule';

setupIonicReact();

const GlobalStyle = createGlobalStyle`
  @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
    html {
      font-size: 1.1rem;
    }
  }
`

const DashboardApp: React.FC = () => {
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
  return (
    <RecoilRoot>
      <ChakraProvider>
        <ThemeProvider theme={light}>
          <GlobalStyle />
          <ThemeWrapper>
            <IonApp>
              <IonPage>
                <IonContent id='pageContent' scrollEvents forceOverscroll={false}>
                  <IonReactRouter>
                    <NotificationContainer />
                    <WebErrorBoundary>
                      <Switch>
                        <Route path="/:location" component={BookingModule} exact />
                        <Route path='/' component={NotFound} exact />
                        <Route path='*' component={NotFound} />
                      </Switch>
                    </WebErrorBoundary>
                  </IonReactRouter>
                </IonContent>
              </IonPage>
            </IonApp>
          </ThemeWrapper>
        </ThemeProvider>
      </ChakraProvider>
    </RecoilRoot>
  );
};

const lazyRetry = function (componentImport: any): any {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    // try to import the component
    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
        return window.location.reload(); // refresh the page
      }
      reject(error); // Default error behaviour as already tried refresh
    });
  });
};

export default DashboardApp;
