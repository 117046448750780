import { IonSkeletonText } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import styled from 'styled-components';
import { ApiService } from '../../api/api-connectors';
import { AutoSuggestion } from '../../api/api-definitions';
import { NotificationService } from '../../services/NotificationService';
import FetchJSON from '../../utils/fetch';
import Icon from '../Media/Icon';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { isNullOrWhitespace } from '../../utils/text-helpers';
import { createUUID } from '../../utils/data-helpers';

interface Codes {
    admin_district: string;
    admin_county: string;
    admin_ward: string;
    parish: string;
    parliamentary_constituency: string;
    ccg: string;
    ccg_id: string;
    ced: string;
    nuts: string;
    lsoa: string;
    msoa: string;
    lau2: string;
}

export interface PostCodeItem {
    postcode: string;
    quality: number;
    eastings: number;
    northings: number;
    country: string;
    nhs_ha: string;
    longitude: number;
    latitude: number;
    european_electoral_region: string;
    primary_care_trust: string;
    region?: any;
    lsoa: string;
    msoa: string;
    incode: string;
    outcode: string;
    parliamentary_constituency: string;
    admin_district: string;
    parish: string;
    admin_county?: any;
    admin_ward: string;
    ced?: any;
    ccg: string;
    nuts: string;
    codes: Codes;
}

export interface PostCodeResult {
    status: number;
    result: PostCodeItem[];
}

const Container = styled.div`
    position: fixed;
    height: 5rem;
    background-color: ${props => props.theme.tertiary};
    display: flex;
    width: 100vw;
    bottom: 0;
    left: 0;
    z-index: 102;
`;

const LocationBox = styled.div`
    background-color: ${props => props.theme.primary};
    padding: 1rem;
    flex: 1 1 auto;

    position: relative;
`

const CrossButton = styled.div`
    position: absolute;
    display: block;
    top: 0rem;
    right: 2rem;
    content: "x";
    font-size: 30px; 
    font-weight: 100;
    color: #FFF;
    line-height: 5rem;
    text-align: center;
    opacity: 0.7;
`

const SearchboxContainer = styled.div<{ active: boolean }>`
    width: ${props => props.active ? 'calc(100vw - 30rem)' : '0'};
    max-width: ${props => props.active ? 'calc(100vw - 30rem)' : '0'};
    transition: width ease 0.4s;
`

const Searchbox = styled(LocationBox)`
    
`


const SearchInput = styled.input`
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${props => props.theme.primaryContrast};
    color: ${props => props.theme.primaryContrast};
    font-size: 1.5rem;
    line-height: 2rem;
    width: 100%;
    padding: 0.5rem;

    &:focus-visible {
        outline: none;
    }
`;

const Arrow = styled.div`
    border: 1px solid #FFF;
    border-width: 0 0 2px 2px;
    width: 30px;
    height: 30px;
    line-height: 0;
    font-size: 0;
    transform: rotate(-135deg);
    display: inline-block;
    position: relative;
    transition: all ease 0.5s;

    &::before {
        content: ' ';
        border-top: 2px solid #FFF;
        width: 3rem;
        display: block;
        transform: rotate(135deg);
        margin-top: 0.45rem;
        margin-left: -0.4rem;
    }
`

const ArrowContainer = styled.div`
    width: 15rem;
    padding-top: 1.5rem;
    text-align: center;
    cursor: pointer;
    color: white;

    &:hover {
        div {
            margin-left: 1rem;
        }
    }
`;

const SearchResults = styled.div<{ active: boolean }>`
    position: fixed;
    bottom: 5rem;
    left: 0;
    height: ${props => props.active ? 'calc(100vh - 5rem)' : '0rem'};
    background-color: ${props => props.theme.secondary};
    z-index: 103;
    width: 50vw;
    transition: height ease 0.6s;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    ion-skeleton-text {
        --background: rgba(0,219,224, 0.065);
        --background-rgb: 0,219,224;
        height: 1rem;
        margin-top: 1rem;
    }
`

const SearchResultsInner = styled.div`
    display: flex;
    padding: 2rem 0;
    flex-direction: column;
    flex: 1;
`

const ResultsPanel = styled.div`
    flex: 1;
    margin: 0 1rem;
    color: white;
    
    &:last-child {
        border: none;
    }
`

const ResultHeading = styled.div`
    color: white;
    font-size: 1.2rem;
    border-bottom: 1px solid white;
`;

const ResultItem = styled.div`
    margin-top: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;

    &:hover {
        text-decoration: underline;
    }
`;

const ResultItemLink = styled(Link)`
    margin-top: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    display: block;

    &:hover {
        text-decoration: underline;
    }
`

const MyLocation = styled.span`
    margin-top: 0.5rem;
    display: inline-block;
`

const Backdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
`

const noResultsMessage = 'There are no suggestions for the searched term';


    
const Searchbar = () => {
    const sessionStorageItem = window.sessionStorage.getItem('selectedPostcode');
    const selectedPostcode: PostCodeItem = sessionStorageItem ? JSON.parse(sessionStorageItem) : null;
    const [searchValue, setSearchValue] = useState<string>('');
    const [locationValue, setLocationValue] = useState<string>(selectedPostcode ? selectedPostcode.postcode : '');
    const [postcodeData, setPostcodeData] = useState<PostCodeItem>(selectedPostcode);
    const [postCodes, setPostCodes] = useState<PostCodeItem[] | undefined>();
    const [suggestions, setSuggestions] = useState<AutoSuggestion[]>([]);
    const [active, setActive] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();
    const validUrl = location.pathname.startsWith('/search') || location.pathname === '/';

    useEffect(() => {
        if (validUrl) {
            if (!selectedPostcode) {
                getGeoLocation()
            }
            if (selectedPostcode) {
                getSuggestions(selectedPostcode)
            }
        }
    }, [])


    const getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(setGeoLocation);
        }
    }

    const setGeoLocation = (location: GeolocationPosition) => {
        FetchJSON.fetch(`https://api.postcodes.io/postcodes?lon=${location.coords.longitude}&lat=${location.coords.latitude}`, 'GET').then((data: PostCodeResult) => {
            if (data.result[0]) window.sessionStorage.setItem('selectedPostcode', JSON.stringify(data.result[0]));
            setPostcodeData(data.result[0])
            setLocationValue(data.result[0].postcode)
        })
    }

    const updateLocationValue = (value: string) => {
        setLocationValue(value)
        setPostcodeData(undefined)
        window.sessionStorage.removeItem('selectedPostcode')
        if (value.length > 2) {
            if (value.length > 4) value = value.replace(/^(.*)(\d)/, "$1 $2");
            FetchJSON.fetch('https://api.postcodes.io/postcodes?q=' + value, 'GET').then((result: PostCodeResult) => {
                setActive(true)
                setPostCodes(result.result || [])
            })
        } else {
            setActive(false)
        }
    }

    const updateSearchValue = (value: string) => {
        setActive(!isNullOrWhitespace(value))
        setSearchValue(value)
    }

    const selectLocation = (x: PostCodeItem) => {
        updateLocationValue(x.postcode);
        setPostcodeData(x);
        window.sessionStorage.setItem('selectedPostcode', JSON.stringify(x))
        getSuggestions(x)
    }

    const getSuggestions = (x: PostCodeItem) => {
        if (validUrl) ApiService.autoSuggestionsApi.List__GET(x.latitude, x.longitude).then(setSuggestions).catch(() => {
            NotificationService.Error('Sorry, there was an error processing the suggestions request')
        })
    }

    const clickResultItem = (value: string) => {
        setActive(false)
        setSearchValue(value)
    }

    const manualSearch = () => {
        setActive(false);
        history.push(`/search?query=${searchValue}`);
    }

    const searcher = new FuzzySearch(suggestions || [], ['name'], {
        caseSensitive: false,
    });
    const filteredSuggestions = searcher.search(searchValue);

    const places = filteredSuggestions.filter(x => x.type.toString() == 'BusinessName').filter((x, i) => i < 2);
    const dishes = filteredSuggestions.filter(x => x.type.toString() == 'DishName').filter((x, i) => i < 4);
    const drinks = filteredSuggestions.filter(x => x.type.toString() == 'DrinkName').filter((x, i) => i < 4);
    const ingredients = filteredSuggestions.filter(x => x.type.toString() == 'DishIngredientName').filter((x, i) => i < 4);
    const businessTag = filteredSuggestions.filter(x => x.type.toString() == 'BusinessTag').filter((x, i) => i < 4);
    const menuName = filteredSuggestions.filter(x => x.type.toString() == 'MenuName').filter((x, i) => i < 4);
    const menuSectionName = filteredSuggestions.filter(x => x.type.toString() == 'MenuSectionName').filter((x, i) => i < 4);

    const noResults = (!places || places.length === 0) && 
                        (!dishes || dishes.length === 0) && 
                        (!drinks || drinks.length === 0) &&
                        (!ingredients || ingredients.length === 0) && 
                        (!businessTag || businessTag.length === 0) &&
                        (!menuName || menuName.length === 0) && 
                        (!menuSectionName || menuSectionName.length === 0);

    if (!validUrl) return null;
    
    return (
        <>
            <SearchResults active={locationValue && locationValue.length > 2 && !postcodeData && active}>
                <SearchResultsInner>
                    <ResultsPanel>
                        <ResultHeading>Select your location</ResultHeading>
                        {postCodes && postCodes.map(x => (
                            <ResultItem onClick={() => selectLocation(x)}><Icon name='location-arrow' />{x.postcode}, {x.admin_district}, {x.country}</ResultItem>
                        ))}
                        {postCodes === undefined && <>
                            <IonSkeletonText animated />
                            <IonSkeletonText animated />
                            <IonSkeletonText animated />
                            <IonSkeletonText animated />
                            <IonSkeletonText animated />
                        </>}
                        {postCodes && postCodes.length === 0 &&
                            <div><br />There are no results for your post code search. Please check your post code is correct.</div>
                        }
                    </ResultsPanel>
                </SearchResultsInner>
            </SearchResults>
            <SearchResults data-testid='searchcontainer' active={searchValue && searchValue.length > 0 && postcodeData && active}>
                <SearchResultsInner>
                    {noResults && <ResultsPanel>
                        {<><br /><Icon name='lightbulb' />{noResultsMessage}</>}
                    </ResultsPanel>}
                    {places && places.length !== 0 && 
                    <ResultsPanel>
                        <ResultHeading>Restaurants</ResultHeading>
                        {places.map(x => (
                            <ResultItemLink to={'/view-business/' + x.identifier} onClick={() => clickResultItem('')}><Icon name='store' /> {x.name}</ResultItemLink>
                        ))}
                    </ResultsPanel>}
                    {dishes && dishes.length !== 0 && <ResultsPanel>
                        <ResultHeading>Dishes</ResultHeading>
                        {dishes.map(x => (
                            <ResultItemLink to={`/search?query=${x.name}`} onClick={() => clickResultItem(x.name)}><Icon name='utensils' /> {x.name}</ResultItemLink>
                        ))}
                    </ResultsPanel>}
                    {drinks && drinks.length !== 0 && <ResultsPanel>
                        <ResultHeading>Drinks</ResultHeading>
                        {drinks.map(x => (
                            <ResultItemLink to={`/search?query=${x.name}`} onClick={() => clickResultItem(x.name)}><Icon name='mug-hot' /> {x.name}</ResultItemLink>
                        ))}
                    </ResultsPanel>}
                    {ingredients && ingredients.length !== 0 && <ResultsPanel>
                        <ResultHeading>Ingredients</ResultHeading>
                        {ingredients.filter((x, i) => i < 4).map(x => (
                            <ResultItemLink to={`/search?query=${x.name}`} onClick={() => clickResultItem(x.name)}><Icon name='seedling' /> {x.name}</ResultItemLink>
                        ))}
                    </ResultsPanel>}
                    {businessTag && businessTag.length !== 0 && <ResultsPanel>
                        <ResultHeading>Venue tag</ResultHeading>
                        {businessTag.filter((x, i) => i < 4).map(x => (
                            <ResultItemLink to={`/search?query=${x.name}`} onClick={() => clickResultItem(x.name)}><Icon name='tag' /> {x.name}</ResultItemLink>
                        ))}
                    </ResultsPanel>}
                    {menuName && menuName.length !== 0 && <ResultsPanel>
                        <ResultHeading>Menu</ResultHeading>
                        {menuName.filter((x, i) => i < 4).map(x => (
                            <ResultItemLink to={`/search?query=${x.name}`} onClick={() => clickResultItem(x.name)}><Icon name='book-open' /> {x.name}</ResultItemLink>
                        ))}
                    </ResultsPanel>}
                    {menuSectionName && menuSectionName.length !== 0 && <ResultsPanel>
                        <ResultHeading>Menu section</ResultHeading>
                        {menuSectionName.filter((x, i) => i < 4).map(x => (
                            <ResultItemLink to={`/search?query=${x.name}`} onClick={() => clickResultItem(x.name)}><Icon name='book-open' /> {x.name}</ResultItemLink>
                        ))}
                    </ResultsPanel>}
                </SearchResultsInner>
            </SearchResults>
            {active && <Backdrop onClick={() => setActive(false)} />}
            <Container>
                <SearchboxContainer active={!!postcodeData}>
                    <Searchbox>
                        <CrossButton data-testid='clearsearchinput' onClick={() => updateSearchValue('')}>x</CrossButton>
                        <SearchInput data-testid='searchinput' onFocus={() => updateSearchValue(searchValue)} placeholder='What would you like to search for?' value={searchValue} onChange={(e) => updateSearchValue(e.target.value)} />
                    </Searchbox>
                </SearchboxContainer>
                <LocationBox>
                    <CrossButton data-testid='clearpostcode' onClick={() => !!postcodeData ? updateLocationValue('') : null}>x</CrossButton>
                    <SearchInput data-testid='postcode' onFocus={() => updateLocationValue(locationValue)} placeholder='Please enter your postcode...' value={locationValue} onChange={(e) => updateLocationValue(e.target.value)} />
                </LocationBox>
                <ArrowContainer data-testid={postcodeData ? 'searchall' : 'uselocation'} onClick={() => postcodeData ? manualSearch() : getGeoLocation()}>
                    {postcodeData ? <Arrow data-testid='searchicon' /> : <MyLocation data-testid='locationicon'><Icon name='location-arrow' /> USE MY LOCATION</MyLocation>}
                </ArrowContainer>
            </Container>
        </>
    );
};

export default Searchbar;