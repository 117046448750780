import { IonButton } from '@ionic/react';
import React, { Children, useRef } from 'react';
import { useHistory } from 'react-router';

interface ComponentProps {
    disabled?: boolean;
    type?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger';
    text?: string;
    onClick?: (e: any) => void;
    children?: React.ReactNode;
    outline?: boolean;
    noOutline?: boolean;
    full?: boolean;
    to?: string;
    small?: boolean;
    inverse?: boolean;
    floatRight?: boolean;
    floatLeft?: boolean;
    className?: string;
    testingId?: string;
    buttonType?: 'button' | 'submit' | 'reset';
}

const CoreButton = ({ text, testingId, children, disabled, className, outline, noOutline, small, inverse, floatRight, floatLeft, onClick, to, type = 'secondary', full = false, buttonType = 'button' }: ComponentProps) => {
  const navigate = useHistory();
  const button = useRef<HTMLIonButtonElement>()
  let float = null;
  if (floatRight) float = 'right';
  if (floatLeft) float = 'left';
  const handleClick = (e) => {
    if (onClick) onClick(e);
    if (to) navigate.push(to);
  }

  if (button.current) {
    button.current.dataset.testid = testingId ? testingId : text;
  }

  return (
    <IonButton
      onClick={handleClick}
      data-testid={testingId ? testingId : text}
      disabled={disabled}
      color={type}
      fill={noOutline ? 'clear' : outline ? 'outline' : 'solid'}
      size={small ? 'small' : 'default'}
      style={{float, width: full ? '100%' : null}}
      className={inverse ? className + ' button-outline-inverse' : className}
      ref={button}
      type={buttonType}
    >{text || children}</IonButton>
  );
};

export default CoreButton;