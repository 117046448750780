import styled from "styled-components";

const base = `
    padding: 0.5rem;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #d6d6d6;
    transition: all ease 0.5s;
    outline: none;

    &.error {
        border: 1px solid red;
    }

    &:focus {
        border-color: black;
    }
`

export const BaseCalendarInput = styled.div`
    ${base}
    line-height: 1.9rem;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 100%;
`

export const BaseFormReadonlyValue = styled.div`
    line-height: 2rem;
    margin: 0.5rem;
    flex: 1 1 auto;
`

const BaseInputButton = styled.div`
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    width: 2rem;
    text-align: center;
    cursor: pointer;
    flex: none;
    border-radius: 0.5rem;
    margin: 0.5rem;
`

export const BaseInputPrimaryButton = styled(BaseInputButton)`
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.primaryContrast};

    &:hover {
        opacity: 0.8;
    }
`

export const BaseInputCancelButton = styled(BaseInputButton)`
    border: 1px solid ${props => props.theme.negative};
    color: ${props => props.theme.negative};

    &:hover {
        opacity: 0.8;
    }
`

export const BaseInputStyle = styled.input`
    ${base}
    
    &:disabled {
        background-color: #edebeb;
    }
`;

export const BaseSelectStyle = styled.select<{hasIcon?: boolean}>`
    ${base}
    ${props => props.hasIcon && `
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-left: none  !important;
    `}
`;

export const BaseIconStyle = styled.div`
    ${base}
    flex: 0;
    line-height: 1.9rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

export const BaseInputWrapper = styled.div`
    display: flex;
`

export const BaseCalendarStyle= styled.div`
    input {
        ${base}
    }

    .react-datepicker__triangle {
        transform: translate(20px, 0px) !important;
    }

    .calendarContainerCustom > div:not(.plusMinusToggle) {
        width: 100%;
    }
`;

export const BaseLabelStyle = styled.label<{required?: boolean}>`
    margin-bottom: 0.5rem;
    display: block;

    &.required::after {
        content: ' *';
        color: red;
    }

    ${props => props.required ? `
        ::after {
            content: ' *';
            color: red;
        }
    ` : ''}
`

export const CheckboxLabelStyle = styled.label`
    padding-left: 0.5rem;

    &.required::after {
        content: ' *';
        color: red;
    }
`

export const BaseErrorMessage = styled.div`
    color: red;
    font-size: 0.9em;
`

export const BaseTextareaStyle = styled.textarea`
    ${base}
    height: auto !important;
`;