import React, { forwardRef, useContext, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment, { Moment } from 'moment';
import 'moment/locale/en-gb';
import i18n from '../../../utils/i18n';
import { createMomentFromValue, DATEONLYFORMAT, DEFAULT_DATE_FORMAT, formatDate, isValidDate, resetTime, toDate } from '../../../utils/date-helpers';
import { useFormUpdate } from '../../../hooks/formState';
import { useRecoilState } from 'recoil';
import { FormControlState } from '../../../controllers/easyFormConsumer';
import { createUUID } from '../../../utils/data-helpers';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import { BaseCalendarInput, BaseCalendarStyle, BaseErrorMessage, BaseInputStyle, BaseLabelStyle } from '../../../theme/input.core.styles';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { BaseThirdPartyCalendarStyle } from './EasyCalendarThirdParty.style';
import Icon from '../../Media/Icon';

interface ComponentProps {
  model?: string;
  dateFormat?: string;
  onChange?: (value: Moment) => void;
  onBlur?: (value: Moment) => void;
  inputName?: string;
  value?: any;
  maxDate?: any;
  minDate?: any;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  id?: string;
  months?: number;
  unlink?: boolean;
  placeholder?: string;
  calendarProps?: any;
  className?: string;
  hideValue?: boolean;
  inline?: boolean;
  hasPlusMinus?: boolean;
  keepTime?: boolean;
  customInput?: React.ReactNode;
  timePicker?: boolean;
}

const EasyCalendar = ({
  model,
  onChange,
  onBlur,
  inputName,
  value,
  maxDate,
  minDate,
  required,
  disabled,
  label,
  id,
  months,
  unlink,
  placeholder = 'DD/MM/YYYY',
  dateFormat,
  className,
  hideValue,
  inline,
  keepTime,
  hasPlusMinus,
  customInput,
  timePicker,
  calendarProps = {},
}: ComponentProps) => {
  const context = useContext(FormContext);
  const uuid = useRef(createUUID());
  const formId = unlink || !context ? null : context;
  const nameToUse = inputName ? inputName : model;
  const [componentState, setComponentState] = useRecoilState(FormControlState(formId || uuid.current, model || inputName));
  const setComponentData = useFormUpdate(formId, model);
  const { error, internalValue } = componentState;
  const valueToUse = value || internalValue;
  const hasLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (model) {
      const rawValue = value;
      const date = rawValue ? toDate(keepTime ? createMomentFromValue(rawValue) : resetTime(rawValue)) : rawValue;
      const [valid] = validate(false, date);
      setComponentData(date, valid);
    }
  }, [])

  useEffect(() => {
    if (hasLoaded.current) {
      if (value !== internalValue) {
        const valueDate = value ? toDate(keepTime ? createMomentFromValue(value) : resetTime(value)) : value;
        const [valid] = validate(true, valueDate);
        setComponentData(value, valid);
      }
    } else {
      hasLoaded.current = true;
    }
  }, [value, minDate, maxDate])

  useEffect(() => {
    setValue(value, true)
  }, [required])

  const setValue = (newDate, preventSetState: boolean = false) => {

    let value = newDate ? createMomentFromValue(newDate) : undefined;
    if (timePicker && value) {
      value = createMomentFromValue(formatDate(moment(), DATEONLYFORMAT) + 'T' + formatDate(value, 'HH:mm:ss'))
    }
    const [valid, newError] = validate(false, value);

    if (model && formId) {
      setComponentData(value, valid);
    }

    if (!preventSetState) setComponentState({ error: newError, internalValue: value });
    if (onChange) onChange(value);
  }

  const validate = (setState: boolean = true, date: string | Date | moment.Moment | null = null): [boolean, string] => {
    if (!date && required) {
      const error = i18n('Required');
      if (setState) setComponentState({ error, internalValue });
      if (setState) setValue(null); // force remove date, we don't get a change event if date is deleted.
      if (setState) setComponentData(date, false);
      return [false, error];
    } if (!isFieldValid(date)) {
      const error = 'This is not a valid date';
      if (setState) setComponentState({ error, internalValue });
      if (setState) setComponentData(date, false);
      return [false, error];
    } else if (minDate && resetTime(createMomentFromValue(date)).isBefore(resetTime(createMomentFromValue(minDate)))) {
      const error = `Date must be after: ${moment(minDate).format(DEFAULT_DATE_FORMAT)}`;
      if (setState) setComponentState({ error, internalValue });
      if (setState) setComponentData(date, false);
      return [false, error];
    } else if (maxDate && resetTime(createMomentFromValue(date)).isAfter(resetTime(createMomentFromValue(maxDate)))) {
      const error = `Date must be before: ${moment(maxDate).format(DEFAULT_DATE_FORMAT)}`;
      if (setState) setComponentState({ error, internalValue });
      if (setState) setComponentData(date, false);
      return [false, error];
    } else {
      if (setState) setComponentState({ error: null, internalValue });
      if (setState) setComponentData(date, true);
      if (setState) setValue(date ? createMomentFromValue(date) : date);
      return [true, null];
    }

  }

  const isFieldValid = (value: any) => {
    if (required && isValidDate(value)) {
      return true;
    } else if (!required && (!value || isValidDate(value))) {
      return true;
    }

    return false;
  }

  const parsedMinDate = isNullOrWhitespace(minDate) ? undefined : toDate(minDate);
  const parsedMaxDate = isNullOrWhitespace(maxDate) ? undefined : toDate(maxDate);
  const parsedValue = valueToUse ? createMomentFromValue(valueToUse) : undefined;

  const timePickerProps = timePicker ? { showTimeSelect: true, showTimeSelectOnly: true, timeIntervals: 15, dateFormat: 'HH:mm' } : {};

  return (
    <div className={className}>
      <BaseThirdPartyCalendarStyle />
      <FormAttibuteContext.Consumer>
        {attr => (
          <BaseCalendarStyle>
            {label &&
              <BaseLabelStyle className={required ? 'required' : ''} htmlFor={id ? id : nameToUse}>
                {label}
              </BaseLabelStyle>
            }

            {!hideValue &&
              <div style={{ display: 'flex' }} className={customInput ? 'calendarContainerCustom' : 'calendarContainer'}>
                {hasPlusMinus &&
                  <div className={'plusMinusToggle minus' + (parsedMinDate !== undefined && parsedValue?.toDate() <= parsedMinDate ? ' disabled' : '')} onClick={() => (parsedMinDate === undefined || parsedValue?.toDate() > parsedMinDate) ? setValue(parsedValue.subtract(1, 'day').toDate()) : {}}><Icon name='chevron-left' /></div>
                }
                <DatePicker
                  dateFormat={dateFormat || 'dd/MM/yyyy'}
                  {...{...timePickerProps, ...calendarProps}}
                  selected={parsedValue && parsedValue?.isValid() ? parsedValue?.toDate() : null}
                  className={`${error ? `error ${hasPlusMinus && 'plusMinusToggleInput'}` : hasPlusMinus && 'plusMinusToggleInput'}`}
                  onChange={(date) => setValue(date)}
                  onBlur={onBlur ? () => onBlur(value) : undefined}
                  disabled={disabled || attr.disabled}
                  monthsShown={months ? months : 1}
                  minDate={parsedMinDate}
                  maxDate={parsedMaxDate}
                  placeholderText={placeholder}
                  calendarStartDay={1}
                  disabledKeyboardNavigation
                  inline={inline}
                  customInput={customInput}
                />
                {hasPlusMinus &&
                  <div className={'plusMinusToggle plus' + (parsedMaxDate !== undefined && parsedValue?.toDate() >= parsedMaxDate ? ' disabled' : '')} onClick={() => (parsedMaxDate === undefined || parsedValue?.toDate() < parsedMaxDate) ? setValue(parsedValue.add(1, 'day').toDate()) : {}}><Icon name='chevron-right' /></div>
                }
              </div>
            }

            {hideValue && <BaseInputStyle readOnly disabled />}

            {!hideValue && error &&
              <BaseErrorMessage>
                {error}
              </BaseErrorMessage>
            }
          </BaseCalendarStyle>
        )}
      </FormAttibuteContext.Consumer>
    </div>
  );
};

export default EasyCalendar;

