import { cloneDeep } from 'lodash';
import React, { Component, useEffect } from 'react';
import styled from 'styled-components';
import { NotificationService } from '../../../services/NotificationService';
import Notification, { NotificationType } from './Notification';
import { useToast } from '@chakra-ui/react';

interface ComponentProps {

}

interface ComponentState {
    notifications: NotificationProps[];
}

interface NotificationProps {
    text: string;
    type: NotificationType;
    done?: boolean;
}

const Container = styled.div`
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 100vw;
    pointer-events: none;
    touch-action: none;
    z-index: 999999999;
`;

// export default class NotificationContainer extends Component<ComponentProps, ComponentState> {
//     constructor(props: ComponentProps) {
//         super(props);

//         this.state = {
//             notifications: []
//         }
//     }

//     componentDidMount() {
//         NotificationService.RegisterHandler(this.handler);
//     }

//     handler = (text: string, type: NotificationType) => {
//         const notifications = cloneDeep(this.state.notifications);
//         notifications.push({
//             text,
//             type,
//         });
//         this.setState({ notifications });
//         toast({
//             title: 'Account created.',
//             description: "We've created your account for you.",
//             status: 'success',
//             duration: 9000,
//             isClosable: true,
//           })
//     }

//     setDone = (index: number) => {
//         const notifications = cloneDeep(this.state.notifications);
//         notifications[index].done = true;
//         this.setState({ notifications });
//     }

//     render() {
//         return (
//             <Container>
//                 {this.state.notifications.map((notification, index) => !notification.done ? (
//                     <Notification type={notification.type} text={notification.text} index={index} setDone={this.setDone} />
//                 ) : null)}
//             </Container>
//         );
//     }
// }

function getType(type: NotificationType): "info" | "warning" | "success" | "error" | "loading" {
    if (type == NotificationType.Success) return 'success';
    if (type == NotificationType.Error) return 'error';
    if (type == NotificationType.Info) return 'info';
    if (type == NotificationType.Warning) return 'warning';
    return 'info'
}

const NotificationContainer = () => {
    const toast = useToast();
    useEffect(() => {
        NotificationService.RegisterHandler(handler);
    }, [])

    const handler = (text: string, type: NotificationType) => {
        toast({
            description: text,
            status: getType(type),
            duration: 2000,
            isClosable: true,
            position: 'top'
        })
    }
    return <></>
}

export default NotificationContainer;