import { Logtail } from "@logtail/browser";
import { captureException } from "@sentry/react";

export class LoggingService {
    private static _logger: Logtail = null;

    static Log(message: string) {
        if (process.env.NODE_ENV === 'production') {
            if (!this._logger) {
                this._logger = new Logtail("S6gSendADb1mGbxwoQzfPiih");
            }
            captureException(message);
            this._logger.error(message);
            this._logger.flush();
        }
    }
}