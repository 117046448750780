import styled from 'styled-components';
import EasyCalendar from '../components/Forms/Calendar';
import EasyCurrencyInput from '../components/Forms/CurrencyInput';
import EasyDropdown from '../components/Forms/Dropdown';
import EasyTextarea from '../components/Forms/Textarea';
import EasyTextInput from '../components/Forms/TextInput';
import EasyColourInput from '../components/Forms/ColourInput';
import NumberInput from '../components/Forms/NumberInput';
import EasyRadioInput from '../components/Forms/RadioInput';
import Time from '../components/Forms/Time';
import BREAKPOINTS from '../config/breakpoints';
import Typeahead from '../components/Forms/EasyTypeahead';

export const DefaultInputStyle = `
    display: block;
    
    input, select, textarea {
        display: block;
        vertical-align: baseline;
        width: 100%;
        box-sizing: border-box;
        font-weight: normal;
        line-height: normal;
        font-family: serif;
        letter-spacing: inherit;
        color: inherit;
        outline: none;
        box-shadow: none;
        height: 3rem;
        padding: 0.5rem;
        font-size: 1rem;
        border: 1px solid #c7c7c7;
        background: white;
        border-radius: 3px;

        &:disabled {
            background-color: #edebeb;
        }
    }

    select {
        font-family: serif, "Font Awesome 6 Pro";
        font-weight: 400;

        option {
            font-weight: 400;
        }
    }

    textarea {
        min-height: 7rem;
    }
`;

export const StyledTextInput = styled(EasyTextInput)`
    ${DefaultInputStyle}
    input, select, textarea {
        background: ${props => props.theme.inputBackground};
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }
`;

export const StyledColourInput = styled(EasyColourInput)`
    ${DefaultInputStyle}
    input, select, textarea {
        background: ${props => props.theme.inputBackground};
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }
`;

export const StyledNumberInput = styled(NumberInput)`
    ${DefaultInputStyle}
    input, select, textarea {
        background: ${props => props.theme.inputBackground};
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }

    .plusMinusToggleInput {
        border-radius: 0px;
        text-align: center;
    }

    .plusMinusToggle {
        border: 1px solid ${props => props.theme.borderColor};
        background-color: #f2f2f2;
        cursor: pointer;
        text-align: center;
        height: 3rem;
        line-height: 3rem;
        width: 3rem;
        font-size: 1.5rem;
        user-select: none;

        &.plus { 
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &.minus { 
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            line-height: 2.7rem;
        }

        &.disabled {
            color: ${props => props.theme.borderColor};
        }
    }
`;

export const StyledRadioInput = styled(EasyRadioInput)`
    ${DefaultInputStyle}
    display: flex;
    line-height: 2rem;
    input {
        height: 2rem;
        width: 3rem;
        display: inline-block;
        accent-color: ${props => props.theme.primary};
        background: ${props => props.theme.inputBackground};
    }

    label {
        display: inline-block;
        line-height: 2rem;
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }
`
export const AmendmentSummary = styled.span`
    text-decoration: line-through;
    text-decoration-color: #b62f2f;
`;

export const BookingInfoTitle = styled.span`
    font-size: 1.1rem;
    margin-left: 2rem;
    float: right;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        float: none;
        margin-left: 0;
        display: block;
        margin-top: 1rem;
    }
`

export const BookingInfoIcon = styled.span`
    margin-left: 1rem;
`;

export const StyledTextarea = styled(EasyTextarea)`
    ${DefaultInputStyle};
    
    input, select, textarea {
        background: ${props => props.theme.inputBackground};
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }
`;

export const StyledTypeahead = styled(Typeahead)`
    ${DefaultInputStyle}
    input, select, textarea {
        background: ${props => props.theme.inputBackground};
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }
`;

export const StyledCalendar = styled(EasyCalendar)`
    ${DefaultInputStyle}
    input, select, textarea {
        background: ${props => props.theme.inputBackground};
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }

    .plusMinusToggleInput {
        border-radius: 0px;
        text-align: center;
    }

    .plusMinusToggle {
        border: 1px solid ${props => props.theme.borderColor};
        background-color: #f2f2f2;
        cursor: pointer;
        text-align: center;
        height: 3rem;
        line-height: 3rem;
        width: 5rem;
        font-size: 1.5rem;
        user-select: none;

        &.plus { 
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &.minus { 
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            line-height: 2.7rem;
        }

        .icon {
            font-weight: 400;
            line-height: 3rem;
            height: 3rem;
        }

        &.disabled {
            color: ${props => props.theme.borderColor};
        }
    }
`;

export const StyledCurrencyInput = styled(EasyCurrencyInput)`
    ${DefaultInputStyle}
    input, select, textarea {
        background: ${props => props.theme.inputBackground};
    }

    &.error {
        border-color: ${props => props.theme.negative};
    }

    .currencySymbolContainer input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    .currencySymbol {
        border: 1px solid ${props => props.theme.borderColor};
        background-color: #f2f2f2;
        text-align: center;
        height: 3rem;
        line-height: 3rem;
        width: 3rem;
        font-size: 1rem;
        user-select: none;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        line-height: 2.7rem;
    }
`;

// export const StyledTimeInput = styled(Time)`
//     ${DefaultInputStyle}
//     input, select, textarea {
//         background: ${props => props.theme.inputBackground};
//     }

//     &.error {
//         border-color: ${props => props.theme.negative};
//     }
// `;

export const StyledDropdown = styled(EasyDropdown)`
    ${DefaultInputStyle};

    select {
        padding: 0 1rem;
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 1em;
    }
`;

export const StyledSelectInput = styled.select`
    ${DefaultInputStyle}
`

export const BigCheckboxStyle = styled.div<{checked?: boolean}>`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 1px solid ${props => props.theme.borderColor};
    transition: all 0.3s ease;
    cursor: pointer;
    ${props => props.checked ? `background-color: ${props.theme.primary}` : ''};
    color: ${props => props.checked ? props.theme.primary : props.theme.tertiary};

    &:hover {
        border: 1px solid ${props => props.theme.primary};
    }

    .fa-times {
        margin: 0.5rem 0.6rem;
    }
`;