import React, { useState } from 'react';
import styled from 'styled-components';
import ShowMoreText from 'react-show-more-text';

interface ComponentProps {
    text: string;
    characters?: number;
    className?: string;
}

const ReadMore = ({ text, className, characters = 125 }: ComponentProps) => {
    const [expanded, setExpanded] = useState(false);

    const onClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setExpanded(!expanded);
    }

    return (
        <ShowMoreWrapper>
            <ShowMoreText>
                <div dangerouslySetInnerHTML={{__html: text}} />
            </ShowMoreText>
        </ShowMoreWrapper>
    );
};

const ShowMoreWrapper = styled.span`
    .show-more-less-clickable {
        color: inherit;
        font-weight: bold;
        display: block;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;



export default ReadMore;