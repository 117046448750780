import { Moment } from "moment";
import { BaseBusiness, BookingManagementResponse } from "../api/api-definitions";
import { NotificationService } from "./NotificationService";
import { DATEONLYFORMAT, formatDate } from "../utils/date-helpers";
import { ExtendedBookingManagementResponse } from "../pages/BusinessDashboard/modules/LocationModules/Bookings/Bookings";

export default class DatabaseService {
    private static readonly _databaseName = 'DishForager';
    private static _db: IDBDatabase;

    public static async Database(): Promise<IDBDatabase> {
        return new Promise<IDBDatabase>((resolve, reject) => {
            if (this._db) resolve(this._db);
            try {
                var DBOpenRequest = window.indexedDB.open(this._databaseName, 4);
                DBOpenRequest.onerror = () => {
                    reject()
                };
                DBOpenRequest.onupgradeneeded = (event) => {
                    this._db = DBOpenRequest.result;
                    if (event.newVersion <= 1) {
                        let bookingObject = this._db.createObjectStore('bookings');
                        bookingObject.createIndex('dateIndex', 'date', { unique: true });
                    }
                    if (event.newVersion <= 2) {
                        let businessObject = this._db.createObjectStore('business');
                        businessObject.createIndex('businessIdIndex', 'businessId', { unique: true });
                    }
                    if (event.newVersion <= 3) {
                        let businessListObject = this._db.createObjectStore('businessList');
                        businessListObject.createIndex('businessListIndex', 'businessListId', { unique: true });
                    }
                    if (event.newVersion <= 4) {
                        let businessPermissionsObject = this._db.createObjectStore('businessPermissions');
                        businessPermissionsObject.createIndex('businessPermissionsIndex', 'businessPermissionsId', { unique: true });
                    }
                };
                DBOpenRequest.onsuccess = () => {
                    this._db = DBOpenRequest.result;
                    resolve(this._db);
                };
            } catch(e) {
                // Can not use this feature
                reject()
            }
        })
    }
}