import React, { useContext, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { BaseErrorMessage } from '../../../theme/input.core.styles';
import { FormControlState } from '../../../controllers/easyFormConsumer';
import { useFormUpdate } from '../../../hooks/formState';
import { createUUID } from '../../../utils/data-helpers';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import styled from 'styled-components';
import Icon from '../../Media/Icon';

interface RadioProps {
    model?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    required?: boolean;
    label?: string;
    id: string;
    value: string;
    unlink?: boolean;
    inputName?: string;
    className?: string;
    asButton?: boolean;
}

const EasyRadioInput = ({
    model,
    onChange,
    checked,
    disabled,
    required,
    label,
    id,
    value,
    unlink,
    inputName,
    className,
    asButton
}: RadioProps) => {
    const context = useContext(FormContext);
    const uuid = useRef(createUUID());
    const formId = unlink || !context ? null : context;
    const nameToUse = inputName ? inputName : model;
    const [componentState, setComponentState] = useRecoilState(FormControlState(formId || uuid.current, model || inputName));
    const setComponentData = useFormUpdate(formId, model);
    const { error, isChecked } = componentState;

    useEffect(() => {
        if (formId && model) {
            const valid = required ? checked : true;
            setComponentData(checked, valid);
        }
        setComponentState({ isChecked: !!checked, error });
    }, [])

    useEffect(() => {
        setComponentState({ isChecked: checked, error });

        if (formId && model) {
            setComponentData(checked, true);
        }
    }, [checked])

    const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (model && formId) setComponentData(e.target.value, true);

        if (onChange) onChange(e);

        setComponentState({ isChecked: e.target.checked })
    }

    return (
        <div className={className}>
            <FormAttibuteContext.Consumer>
                {attr => (
                    <>
                        <RadioInput type='radio'
                            hidden={asButton}
                            checked={isChecked}
                            name={nameToUse}
                            id={id}
                            disabled={disabled || attr.disabled}
                            required={required}
                            onChange={(e) => setValue(e)}
                            value={value}>
                        </RadioInput>
                        {!asButton && label &&
                            <label htmlFor={id}>
                                {label}
                            </label>
                        }
                        {asButton && label &&
                            <RadioButton htmlFor={id} selected={isChecked}>
                                {isChecked && <Icon name='check' />}{label}
                            </RadioButton>
                        }
                        {error &&
                            <BaseErrorMessage className=''>
                                {error}
                            </BaseErrorMessage>
                        }
                    </>
                )}
            </FormAttibuteContext.Consumer>
        </div>
    );
};

const RadioButton = styled.label<{selected?: boolean;}>`
    height: 3rem;
    width: 100%;
    line-height: 3rem !important;
    border: 1px solid ${props => props.theme.borderColor};
    text-align: center;
    cursor: pointer;
    border-radius: 0.5rem;

    ${props => props.selected && `
        background-color: ${props.theme.secondary};
        color: ${props.theme.secondaryContrast};
    `}

    ${props => !props.selected && `
        &:hover {
            background-color: #f7f7f7;
        }
    `}
`;

const RadioInput = styled.input<{hidden?: boolean}>`
    ${props => props.hidden && `
        opacity: 0;
        width: 0;
        height: 0;
        user-select: none;
    `}
`

export default EasyRadioInput;