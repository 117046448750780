import React from 'react';
import styled from 'styled-components';
import CoreButton from '../components/Forms/Button';
import PageContainer from '../components/Layout/PageContainer';
import { H1 } from '../components/Typography/Headings';
import { usePageName } from '../hooks/usePageName';
import { Column, Row } from '../components/Layout/Grid';

const NotFoundContainer = styled(PageContainer)`
    text-align: center !important;
    margin: 6rem 0;

    h1 {
        text-align: center !important;
    }

    p {
        margin: 1rem 0;
    }

    img {
      border-radius: 50%;
      width: 100%;
      height: auto;
      max-width: 20rem;
    }
`

const NotFound = () => {
  usePageName('404')
  return (
    <NotFoundContainer>
      <Row>
        <Column size={4} mobile={12}>
          <img src='/assets/graphics/notfound.jpg' />
        </Column>
        <Column size={8} mobile={12}>
          <H1>Oops, sorry, this page does not exist!</H1>
          <p>We have been alerted that this page has been eaten and are cooking up a fresh one!</p>
          {/* <p>In the mean time, check out what is near you to explore!</p>
            <CoreButton to='/search/'>What is near me</CoreButton> */}
          <CoreButton to='/'>Take me home</CoreButton>
        </Column>
      </Row>
    </NotFoundContainer>
  );
};

export default NotFound;