import { ApiService } from "../api/api-connectors";
import { BaseBusiness } from "../api/api-definitions";
import BusinessListCacheService from "./BusinessListCacheService";
import { NotificationService } from "./NotificationService";

export default class BusinessListService {
    private static _promise: Promise<BaseBusiness[]> = null;
    private static _data: BaseBusiness[] = null;

    public static Clear(): void {
        this._data = null;
        this._promise = null;
    }

    public static async GetBusinessList(force?: boolean, parentId?: number): Promise<BaseBusiness[]> {
        if (force) {
            this._data = null;
            this._promise = null;
        }
        if (this._data) {
            return new Promise(resolve => {
                resolve(this._data)
            });
        } else {
            this._data = null;
            if (!this._promise) {
                if (parentId) {
                    this._promise = ApiService.appUser.GetBusinesses__GET(parentId);
                } else {
                    this._promise = ApiService.appUser.GetBusinesses__GET('');
                }
            }
            await this._promise.then(data => {
                // if (parentId) {
                //     BusinessListCacheService.UpsertBusinessList(parentId.toString(), data)
                // } else {
                //     BusinessListCacheService.UpsertBusinessList('null', data)
                // }
                this._data = data;
            }).catch(() => {
                NotificationService.Error('We encountered an error when trying to load your dashboard.')
            })

            return this._data;
        }
    }
}