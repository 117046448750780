import { useParams } from 'react-router';
import { Column, Row } from '../components/Layout/Grid';
import { StyledCalendar, StyledDropdown, StyledNumberInput, StyledSelectInput, StyledTextInput, StyledTextarea } from '../theme/input.styles';
import Icon from '../components/Media/Icon';
import { useEffect, useRef, useState } from 'react';
import Loader from '../components/Layout/Loader';
import { DATABASE_TIME_FORMAT, DATEONLYFORMAT, DATE_WITH_DAY_OF_WEEK_FORMAT, DEFAULT_DATE_FORMAT, businessNowTime, createMomentFromValue, formatDate, secondsToTime } from '../utils/date-helpers';
import moment, { Moment } from 'moment';
import { ApiService } from '../api/api-connectors';
import { ApiBookingTimesWidget, ApiBookingTimesRequest, ApiBusinessBookingDetails, ApiMakeBookingRequest, ApiPaymentRequest, BookingError, BookingTimeSlots, DateAndTimeSlots, HeldBookingResponse, HoldClientInfoRequest, PublicAmendBookingRequest, EnumWidgetFieldType, SpecialOccasion, TimeSlot, ExperienceType, EventsAvailabilityResponse, ExperienceWidgetLayout, BookingAvailabilityTimesWidget, ThemeOptions, ApiMakeBookingResponse } from '../api/api-definitions';
import { BookingSpecialOccasions, Constants, EMAIL_REGEX, HEADING_FONT, TELEPHONE_REGEX } from '../constants';
import Checkbox from '../components/Forms/Checkbox';
import { getQueryParams, pushEvent } from '../utils/data-helpers';
import FormWrapper from '../components/Forms/FormWrapper';
import { DropdownItem } from '../components/Forms/Dropdown';
import { isNullOrWhitespace } from '../utils/text-helpers';
import Checkout from '../components/Payments/Checkout';
import { NotificationService } from '../services/NotificationService';
import { captureException } from '@sentry/react';
import { BookingModuleContainer, InfoMessage, Title, ButtonBack, TransitionWrapper, YourDetails, InfoForm, FormRow, TimeRow, ButtonTime, ButtonOutline, BookingQuickInfo, Checkmark, ThankYou, PoweredBy, Logo, Transition, Button, SubTitle, BookingTabs, BookingTab, GlobalWidgetStyle, SectionTitle, ExperienceOptionContainer, ExperienceOption, BookingQuickInfoTwo, BookingQuickInfoItem, ManageMyBookingLink } from './booking.styles';
import BookingExperienceOption from './BookingExperienceOption';
import { Link } from 'react-router-dom';

const mobileSize = 6;
const tabletSize = 3;
const desktopSize = 3;
const TIMEFORMAT = 'h:mm A';
const ERROR = 'Something went wrong, please try again later.';
const ERRORMESSAGE = 'Sorry, we were unable to complete your booking, please try again.';
const AMENDERRORMESSAGE = 'Sorry, we were unable to amend your booking, please try again.';
const HOLDERRORMESSAGE = 'Sorry, we were unable to hold the selected time slot for your booking.';


interface FormData {
    guests: number;
    date: Moment;
    time: string;
    experienceId?: number;
}

function getClosestFifteenMinutes(): string {
    const start = businessNowTime();
    const remainder = 15 - (start.minute() % 15);
    const time = moment(start).add(remainder, "minutes");
    if (time.isBefore(businessNowTime())) {
        time.add(15, 'minutes')
    }
    return time.format("HH:mm");
}

function generatePeopleList(limit: number): DropdownItem[] {
    const list: DropdownItem[] = [];
    for (let i = 1; i <= limit; i++) {
        list.push({
            value: i.toString(),
            text: i === 1 ? '1 person' : `${i} people`
        })
    }
    list.push({
        value: 'MAX',
        text: `${(limit + 1)}+ people`
    })
    return list;
}

interface Props {
    previewLocation?: string;
    previewBusinessData?: ApiBusinessBookingDetails;
    defaultDateTime?: Moment;
    bookingHash?: string;
    bookingReference?: string;
    previousSpecialRequests?: string;
    previousOccasion?: SpecialOccasion;
    previousGuests?: number;
    onUpdateBooking?: () => void;
}

const BookingModule = ({
    previewLocation,
    previewBusinessData,
    defaultDateTime,
    bookingHash,
    bookingReference,
    previousGuests,
    previousSpecialRequests,
    previousOccasion,
    onUpdateBooking
}: Props) => {
    const routeParams: any = useParams();
    const params = getQueryParams();
    const [loading, setLoading] = useState(true)
    const [nextAvailLoading, setNextAvailLoading] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [booked, setBooked] = useState(false)
    const [appError, setAppError] = useState(false)
    const [noBusiness, setNoBusiness] = useState(false)
    const [details, setDetails] = useState<ApiMakeBookingRequest>({ specialRequests: previousSpecialRequests, specialOccasion: previousOccasion })
    const [selectedTimeDropdown, setSelectedTimeDropdown] = useState<string>(defaultDateTime ? '0001-01-01T' + defaultDateTime.format('HH:mm') + ':00' : undefined);
    const [selectedTime, setSelectedTime] = useState<string>();
    const [selectedTimeForExperienceSelection, setSelectedTimeForExperienceSelection] = useState<string>();
    const [bookingError, setBookingError] = useState<string>();
    const [bookingRef, setBookingRef] = useState<string>();
    const [bookingResponse, setBookingResponse] = useState<ApiMakeBookingResponse>();
    const [selectedTab, selectTab] = useState<number>(params.event ? 1 : 0);
    const [selectedExperience, setSelectedExperience] = useState<number>();
    const [bookingErrors, setBookingErrors] = useState<BookingError[]>([])
    const [business, setBusiness] = useState<ApiBusinessBookingDetails>();
    const businessRef = useRef<ApiBusinessBookingDetails>();
    const [holdDetails, setHoldDetails] = useState<HeldBookingResponse>();
    const [eventsAvailabilityResponse, setEventsAvailabilityResponse] = useState<EventsAvailabilityResponse>()
    const [searchableExperience, setSearchableExperience] = useState<number>();
    const [searchableEvent, setSearchableEvent] = useState<number>();
    const specialEventDates = useRef<Date[]>([])
    const [eventGuests, setEventGuests] = useState<number>(2);
    const [timer, setTimer] = useState<Moment>();
    const [times, setTimes] = useState<DateAndTimeSlots>();
    const [nextAvail, setNextAvail] = useState<ApiBookingTimesWidget>()
    const [formData, setFormData] = useState<FormData>({
        guests: 2,
        date: defaultDateTime || businessNowTime(),
        time: defaultDateTime ? defaultDateTime.format(TIMEFORMAT) : getClosestFifteenMinutes()
    })
    const lastDateSearch = useRef<string>();
    const [paymentIntent, setPaymentIntent] = useState<string>();
    const selectedTimeRef = useRef<string>();
    const nextAlternative = useRef<boolean>();
    const [hasLoadedFirstData, setHasLoadedFirstData] = useState<boolean>(false);
    const maxPeople = useRef<number>();
    const availableTimes = useRef<BookingTimeSlots>();
    const [showExperienceSelection, setShowExperienceSelection] = useState<number[]>();
    const partyTooBig = formData.guests.toString() == 'MAX' || (maxPeople.current ? +formData.guests > maxPeople.current : false);

    useEffect(() => {
        if (previewLocation) {
            availableTimes.current = {
                timeSlots: {
                    Monday: true,
                    Tuesday: true,
                    Wednesday: true,
                    Thursday: true,
                    Friday: true,
                    Saturday: true,
                    Sunday: true,
                }
            }
            maxPeople.current = 2;
            setBusiness(previewBusinessData)
            businessRef.current = previewBusinessData;
            setLoading(false)
            setLoaded(true)
            setTimes({
                date: formatDate(businessNowTime(), DATABASE_TIME_FORMAT),
                shiftLabelAndTimeSlots: {
                    'Lunch': {
                        slots: [
                            {
                                slot: '0001-01-01T14:00:00'
                            },
                            {
                                slot: '0001-01-01T14:15:00'
                            },
                            {
                                slot: '0001-01-01T14:30:00'
                            },
                            {
                                slot: '0001-01-01T14:45:00'
                            },
                            {
                                slot: '0001-01-01T15:00:00'
                            }
                        ]
                    },
                    'Dinner': {
                        slots: [
                            {
                                slot: '0001-01-01T18:00:00'
                            },
                            {
                                slot: '0001-01-01T18:15:00'
                            },
                            {
                                slot: '0001-01-01T18:30:00'
                            },
                            {
                                slot: '0001-01-01T18:45:00'
                            },
                            {
                                slot: '0001-01-01T19:00:00'
                            }
                        ]
                    }
                }
            })
            setHasLoadedFirstData(true)
        } else {
            if (isNullOrWhitespace(routeParams.location)) {
                pushEvent('bookingAppLoadError', { 'locationId': 'Not specified' })
                setAppError(true)
                return;
            }
            ApiService.makebooking.Getlocation__GET(routeParams.location, !!routeParams.ref).then(data => {
                if (isNullOrWhitespace(data?.name)) {
                    setNoBusiness(true)
                } else {
                    if (data.specialEventDates) {
                        specialEventDates.current = Object.keys(data.specialEventDates).map(x => createMomentFromValue(x).toDate())
                    }
                    moment.tz.setDefault(data.timeZone);
                    if (data.timeSlotsPerDay?.timeSlots) {
                        availableTimes.current = data.timeSlotsPerDay;
                        maxPeople.current = data.maximumBookableParty;
                        const todaysSlots = data.timeSlotsPerDay.timeSlots[createMomentFromValue(formData.date).format('dddd')];
                        if (!defaultDateTime && !todaysSlots) {
                            setFormData({
                                ...formData,
                                date: createMomentFromValue(data.firstAvailableDate) || null
                            })
                        } else if (!defaultDateTime && !!data.firstAvailableDate) {
                            setFormData({
                                ...formData,
                                date: createMomentFromValue(data.firstAvailableDate) || formData.date
                            })
                        }
                        setBusiness(data)
                        businessRef.current = data;
                        document.body.style.backgroundColor = data.theme.backgroundColour;
                        document.body.style.color = data.theme.textColour;
                        var css = `#pageContent { --ion-background-color: ${data.theme.backgroundColour} !important; }`,
                            head = document.head || document.getElementsByTagName('head')[0],
                            style = document.createElement('style');

                        head.appendChild(style);
                        // @ts-ignore
                        style.type = 'text/css';
                        // @ts-ignore
                        if (style.styleSheet) {
                            // This is required for IE8 and below.
                            // @ts-ignore
                            style.styleSheet.cssText = css;
                        } else {
                            style.appendChild(document.createTextNode(css));
                        }
                        if (isNullOrWhitespace(params.setup_intent) && isNullOrWhitespace(params.payment_intent)) {
                            if (data.specialEvents) {
                                if (params.event) {
                                    let peopleToSearchFor = 2;
                                    if (data.specialEvents[params.event].minimumPeople && data.specialEvents[params.event].minimumPeople > 2) {
                                        peopleToSearchFor = data.specialEvents[params.event].minimumPeople;
                                    }
                                    if (data.specialEvents[params.event].maximumPeople && data.specialEvents[params.event].maximumPeople < 2) {
                                        peopleToSearchFor = 1;
                                    }
                                    nextAvailSearch(+params.event, peopleToSearchFor)
                                }
                            }
                            if (defaultDateTime) {
                                dateChange(defaultDateTime, true, previousGuests);
                            } else {
                                dateChange(createMomentFromValue(data.firstAvailableDate) || formData.date, true);
                            }
                            setLoading(false)
                        } else {
                            const { previousFormData, previousDetails } = JSON.parse(sessionStorage.getItem('cd'));
                            setDetails(previousDetails);
                            setFormData(previousFormData);
                            const request: ApiMakeBookingRequest = {
                                ...previousDetails,
                                chargedCard: params.takeNow == 'true',
                                cardIntent: params.setup_intent || params.payment_intent,
                                selectedSlotInformation: {
                                    locationId: routeParams.location,
                                    holdReference: params.holdRef,
                                },
                            }
                            ApiService.makebooking.Book__POST(request).then(bookingResponse => {
                                window.history.replaceState(null, '', '/' + routeParams.location)
                                if (bookingResponse.success) {
                                    setBookingRef(bookingResponse.bookingReference);
                                    setBookingError(undefined);
                                    setBookingResponse(bookingResponse);
                                    setBooked(true);
                                    setSelectedTime(bookingResponse.bookingDateAndTime);
                                    setFormData({
                                        guests: bookingResponse.guests,
                                        date: createMomentFromValue(bookingResponse.bookingDateAndTime),
                                        time: ''
                                    })
                                    setLoading(false)
                                } else {
                                    setLoading(false)
                                    setBookingError(ERRORMESSAGE)
                                }

                                if (bookingResponse.errors) {
                                    setBookingErrors(bookingResponse.errors)
                                } else {
                                    setBookingErrors([])
                                }
                            }).catch(() => {
                                setLoading(false)
                                setBookingError(ERRORMESSAGE)
                            })
                        }
                    } else {
                        pushEvent('bookingAppLoadError', { 'locationId': routeParams.location })
                        setAppError(true)
                    }
                }
            }).catch((e) => {
                captureException(e);
                NotificationService.Error('Sorry, there has been an error loading the application.')
            })
        }
    }, [])

    useEffect(() => {
        if (hasLoadedFirstData) dateChange(formData.date)
    }, [formData.guests])

    const getApiBookingTimeRequest = (date: string | Moment | Date, guests?: number, experienceId?: number): ApiBookingTimesRequest => {
        return {
            requestedTime: formatDate(createMomentFromValue(date).startOf('day'), DATABASE_TIME_FORMAT),
            guests: guests || formData.guests,
            locationId: routeParams.location,
            bookingReference: isNullOrWhitespace(bookingReference) ? null : bookingReference,
            experienceId
        }
    }

    const nextAvailSearch = (experienceId: number = null, guestsOverride: number = null) => {
        if (isNullOrWhitespace(previewLocation)) {
            if (partyTooBig) {
                setTimes({
                    date: '',
                    shiftLabelAndTimeSlots: {}
                })
            } else {
                let preventSearch = false;
                if (experienceId && (guestsOverride || eventGuests)) {
                    preventSearch = ((guestsOverride || eventGuests) > getMaximumPartyForEvent(experienceId) || (guestsOverride || eventGuests) < (businessRef.current.specialEvents[experienceId].minimumPeople || 1));
                }
                if (guestsOverride) setEventGuests(guestsOverride);
                if (experienceId) setSearchableEvent(experienceId);
                setBookingRef(undefined)
                setBookingError(undefined)
                releaseHold()
                if (preventSearch) {
                    setNextAvail({ availability: [] })
                    setLoaded(true)
                } else {
                    setNextAvailLoading(true)
                    ApiService.makebooking.GetNextAvailable__POST(getApiBookingTimeRequest(formData.date, guestsOverride, experienceId)).then((data) => {
                        setNextAvail(data)
                        setLoaded(true)
                        setNextAvailLoading(false)
                    }).catch((e) => {
                        captureException(e);
                        setLoaded(false);
                        setNextAvailLoading(false);
                        setBookingError(ERROR)
                    })
                }
            }
        }
    }

    const releaseHold = () => {
        const holdRef = sessionStorage.getItem('fhr');
        if (!isNullOrWhitespace(holdRef)) {
            ApiService.makebooking.ReleaseHold__POST(holdRef).finally(() => {
                sessionStorage.removeItem('fhr')
                setHoldDetails(undefined)
            })
        }
    }

    const amendBooking = () => {
        setLoading(true);

        if (!details.specialOccasion) {
            details.specialOccasion = SpecialOccasion.NotSet;
        }

        const request: PublicAmendBookingRequest = {
            newSpecialRequests: details.specialRequests,
            newSpecialOccasion: details.specialOccasion,
            locationId: routeParams.location,
            holdReference: holdDetails.holdReference,
            bookingReference: bookingReference,
            hash: bookingHash
        }
        ApiService.makebooking.AmendBooking__POST(request).then(bookingResponse => {
            if (bookingResponse.success) {
                setLoading(false);
                onUpdateBooking();
            } else {
                setLoading(false)
                if (isNullOrWhitespace(bookingResponse.info)) {
                    setBookingError(AMENDERRORMESSAGE)
                } else {
                    setBookingError(bookingResponse.info)
                }
            }
        }).catch(() => {
            setLoading(false)
            setBookingError(AMENDERRORMESSAGE)
        })
    }

    const confirmHold = (date: Moment = null, time: string = null) => {
        setBookingError(undefined);
        setBookingErrors(undefined);
        if (isNullOrWhitespace(previewLocation)) {
            setLoading(true)
            setBookingError(undefined)
            setBookingRef(undefined)
            releaseHold()
            const request: ApiBookingTimesRequest = {
                requestedTime: formatDate(date || formData.date, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + (time || selectedTimeDropdown).split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location,
                bookingReference: bookingReference,
                experienceId: selectedExperience || searchableEvent
            }
            if (searchableEvent) setSelectedExperience(searchableEvent)
            ApiService.makebooking.Hold__POST(request).then((holdResponse) => {
                if (holdResponse.success) {
                    setSelectedTime((time || selectedTimeDropdown))
                    selectedTimeRef.current = (time || selectedTimeDropdown);
                    sessionStorage.setItem('fhr', holdResponse.holdReference);
                    setTimer(moment().add(5, 'minutes'))
                    setHoldDetails(holdResponse)
                } else {
                    if (isNullOrWhitespace(holdResponse.error?.message)) {
                        setBookingError('Sorry, we were not able to hold the requested time. Please try again.')
                    } else {
                        setBookingError(holdResponse.error.message)
                    }
                    dateChange(date || formData.date)
                }
                setLoading(false)
                setLoaded(true)
            }).catch(() => {
                setLoading(false)
                setLoaded(true)
                setBookingError(HOLDERRORMESSAGE)
            })
        }
    }

    const hold = (date: Moment = null, time: string = null, slot?: BookingAvailabilityTimesWidget) => {
        setBookingError(undefined);
        let fullSelectedTime: TimeSlot = null;
        if (!slot) {
            Object.keys(times.shiftLabelAndTimeSlots).forEach(x => {
                const searchTime = times.shiftLabelAndTimeSlots[x].slots.find(x => x.slot === (time || selectedTimeDropdown))
                if (searchTime) fullSelectedTime = searchTime;
            })
        }
        if ((business?.specialEvents || business?.experiences) && !searchableEvent) {
            setLoading(true)
            const request: ApiBookingTimesRequest = {
                requestedTime: formatDate(date || formData.date, DATABASE_TIME_FORMAT).split('T')[0] + 'T' + (time || selectedTimeDropdown).split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location,
                bookingReference: bookingReference
            }
            ApiService.makebooking.GetEventAvailability__POST(request).then((response) => {
                if (!response.availableIds || Object.keys(response.availableIds).length == 0) {
                    confirmHold(date, time)
                } else {
                    setEventsAvailabilityResponse(response)
                    setLoading(false)
                    setShowExperienceSelection(slot ? [] : fullSelectedTime.eventIds)
                    if (searchableExperience && response.availableIds[searchableExperience]) {
                        setSelectedExperience(searchableExperience)
                    }
                    if (searchableEvent && response.availableIds[searchableEvent]) {
                        setSelectedExperience(searchableEvent)
                    }
                    setSelectedTimeForExperienceSelection((time || selectedTimeDropdown))
                }
            }).catch(() => {
                setLoading(false)
                setBookingError(ERROR)
            })
        } else {
            confirmHold(date, time)
        }
    }

    const takeClientDetails = () => {
        if (holdDetails?.depositRequired) {
            processPaymentIntent()
        } else {
            if (!!bookingHash) {
                amendBooking();
            } else {
                book()
            }
        }
    }

    const processPaymentIntent = () => {
        sessionStorage.setItem('cd', JSON.stringify({ previousFormData: formData, previousDetails: details }))
        setLoading(true);
        const request: ApiPaymentRequest = {
            holdClientInfoRequest: {
                ...details,
                holdReference: sessionStorage.getItem('fhr'),
            },
            apiBookingTimesRequest: {
                requestedTime: moment(formData.date).format('YYYY-MM-DD') + 'T' + selectedTime.split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location
            }
        }
        const apiCall = holdDetails?.takeDepositNow ? ApiService.makebooking.GetClientPaymentIntent__POST(request) : ApiService.makebooking.GetClientSetupIntent__POST(request);
        apiCall.then((holdResponse) => {
            if (holdResponse.success) {
                setPaymentIntent(holdResponse.info)
            } else {
                // handle error
            }
        })
    }

    const book = () => {
        setLoading(true);
        const customFields: { [key: string]: any } = details;
        let customFieldList: string[] = [];
        Object.keys(customFields).forEach(key => {
            if (key.indexOf('Custom-') > -1) {
                const field = business.customFields[+key.split('-')[1]]
                if (field) {
                    customFieldList.push(`${field.id}~~~${customFields[key]}`)
                }
            }
        })
        const request: ApiMakeBookingRequest = {
            ...details,
            customFields: customFieldList.join('|||'),
            selectedSlotInformation: {
                requestedTime: moment(formData.date).format('YYYY-MM-DD') + 'T' + selectedTime.split('T')[1],
                guests: formData.guests,
                locationId: routeParams.location,
                holdReference: sessionStorage.getItem('fhr'),
            },
        }
        ApiService.makebooking.Book__POST(request).then(bookingResponse => {
            if (bookingResponse.success) {
                setBookingRef(bookingResponse.bookingReference);
                setBookingResponse(bookingResponse);
                setBookingError(undefined);
                setBooked(true);
            } else {
                setLoading(false)
                setBookingError(ERRORMESSAGE)
            }

            if (bookingResponse.errors) {
                setBookingErrors(bookingResponse.errors)
            } else {
                setBookingErrors([])
            }
        }).catch(() => {
            setLoading(false)
            setBookingError(ERRORMESSAGE)
        })
    }

    const isValidDate = (date): boolean => {
        const momentDate = createMomentFromValue(date);
        if (business.specialEventDates && business.specialEventDates[formatDate(momentDate, DATABASE_TIME_FORMAT)]) return true;
        if (momentDate.isBefore(moment().subtract(1, 'days'))) {
            return false;
        }
        if (business.maximumFutureDaysForCalendar && momentDate.isAfter(moment().add(business.maximumFutureDaysForCalendar - 1, 'days'))) {
            return false;
        }
        if (business.closedExceptionDays && business.closedExceptionDays.hasOwnProperty(momentDate.format('YYYY-MM-DD') + 'T00:00:00')) return false;
        const slot = availableTimes.current?.timeSlots[momentDate.format('dddd')];
        return slot;
    }

    const experienceTypeShouldShow = (business: ApiBusinessBookingDetails): boolean => {
        return (!!business?.specialEvents && Object.keys(business?.specialEvents)?.length > 0) ||
            (!!business?.experiences && Object.keys(business?.experiences)?.length > 0);
    }

    const getSelectedExperienceName = (selectedExperience: number, business: ApiBusinessBookingDetails): string => {
        if (!!selectedExperience && business.experiences[selectedExperience]) {
            return business.experiences[selectedExperience].name
        } else if (!!selectedExperience && business.specialEvents[selectedExperience]) {
            return business.specialEvents[selectedExperience].name;
        } else {
            return 'Standard booking';
        }
    }

    const dateChange = (date: Date | Moment, firstLoad?: boolean, guests?: number, experienceId?: number, noExperience?: boolean) => {
        const formattedTime = '0001-01-01T' + formatDate(date, 'HH:mm') + ':00';
        if (!firstLoad && formatDate(date, DATEONLYFORMAT) == formatDate(formData.date, DATEONLYFORMAT) && guests == formData.guests && searchableExperience === experienceId) return;
        const formattedLastSearch = formatDate(date, DATEONLYFORMAT) + '-' + (guests || formData.guests) + '-' + (noExperience ? '0' : (experienceId || searchableExperience) ? (experienceId || searchableExperience) : '0');
        if (!previewLocation && (guests || formData.guests).toString() !== 'MAX' && lastDateSearch.current != formattedLastSearch) {
            const request = getApiBookingTimeRequest(date, (guests || formData.guests), noExperience ? undefined : (experienceId || searchableExperience))
            lastDateSearch.current = formattedLastSearch;
            ApiService.makebooking.GetForDate__POST(request).then((response) => {
                setTimes(response);
                setFormData({ ...formData, date: createMomentFromValue(date), guests: guests || formData.guests })
                if (!hasLoadedFirstData) setHasLoadedFirstData(true);
                if (!firstLoad && !bookingReference) {
                    setSelectedTimeDropdown('');
                } else if (guests) {
                    setSelectedTimeDropdown(formattedTime);
                }
            })
        }
    }

    const onUpdateSearchableExperience = (experienceId?: number) => {
        dateChange(formData.date, false, formData.guests, experienceId, !experienceId);
        setSearchableExperience(experienceId);
    }

    const updateSelectedExperience = (experienceId?: number) => {
        setBookingError(undefined);
        setSelectedExperience(experienceId)
    }

    const changeTab = (index: number) => {
        if (searchableEvent) setSearchableEvent(undefined)
        if (nextAvail) setNextAvail(undefined)
        selectTab(index)
    }

    const maxPeopleForEvents = () => {
        let max = 0;
        Object.keys(business.specialEvents).forEach(key => {
            const eventMax = getMaximumPartyForEvent(+key)
            if (eventMax > max) max = eventMax;
        });
        if (max == 0) return 20;
        return max;
    }

    const getMaximumPartyForEvent = (eventId: number) => {
        const event = businessRef.current.specialEvents[eventId];
        if (businessRef.current.maximumBookableParty) {
            return event.maximumPeople > 1000 ? businessRef.current.maximumBookableParty : event.maximumPeople;
        } else if (event.maximumPeople > 1000) {
            return event.maximumPeople;
        }
        if (businessRef.current.maximumBookableParty) return businessRef.current.maximumBookableParty;
        return 20;
    }

    if (appError) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <InfoMessage widgetTheme={business?.theme}>
                Sorry, we encountered an error and are unable to load the booking application. Please reload and try again.
            </InfoMessage>
        </BookingModuleContainer>
    )

    if (noBusiness) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <InfoMessage widgetTheme={business?.theme}>
                Sorry, we could not find the specified business.
            </InfoMessage>
        </BookingModuleContainer>
    )

    if (!hasLoadedFirstData) return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <Loader />
        </BookingModuleContainer>
    )

    const specialOccasionOptions: DropdownItem[] = [];

    if (!isNullOrWhitespace(business?.specialOccasions)) {
        const occasionSplit = business?.specialOccasions.split('|');
        Object.keys(BookingSpecialOccasions()).forEach(occasion => {
            if (occasionSplit.indexOf(occasion) > -1) specialOccasionOptions.push({ value: occasion, text: BookingSpecialOccasions()[occasion].label });
        });
    }

    let hasTimeSlots = false;
    let hasMutipleShifts = false;
    let nextAvailHasMultipleShifts = nextAvail?.availability?.find(date => Object.keys(date.shiftLabelAndTimes).length > 1)
    let timeOptions: DropdownItem[] = [];
    const eventBreaksGuestRules = searchableEvent && (eventGuests > getMaximumPartyForEvent(searchableEvent) || eventGuests < (business.specialEvents[searchableEvent].minimumPeople || 1));

    if (times) Object.keys(times.shiftLabelAndTimeSlots).forEach((label, index) => {
        if (times.shiftLabelAndTimeSlots[label].slots.length > 0) hasTimeSlots = true;
        if (index > 0) hasMutipleShifts = true;
        times.shiftLabelAndTimeSlots[label].slots.forEach(time => {
            timeOptions.push({
                value: time.slot,
                text: moment(time.slot).format(TIMEFORMAT) + (time.eventIds?.length > 0 ? '*' : '') + (time.eventOnly ? ' (event only)' : ''),
                group: Object.keys(times.shiftLabelAndTimeSlots).length > 1 ? label : undefined
            })
        });
    });

    return (
        <BookingModuleContainer widgetTheme={business?.theme}>
            <GlobalWidgetStyle widgetTheme={business?.theme} />
            {!business && <Loader />}
            {business &&
                <>
                    <Title widgetTheme={business?.theme}>{business.name}</Title>
                    {!booked && !loading && !!times && ((!!selectedTime && loaded) || showExperienceSelection) && !!!bookingRef &&
                        <ButtonBack
                            style={{ marginTop: '0.5rem' }}
                            widgetTheme={business?.theme}
                            type='button'
                            onClick={() => { setSelectedTime(undefined); setSelectedExperience(undefined); setShowExperienceSelection(undefined); selectedTimeRef.current = undefined; setBookingError(undefined); setBookingErrors([]); releaseHold(); }}>
                            <Icon name='chevron-left' /> Back</ButtonBack>
                    }
                    {!bookingError && !loading && loaded && times && selectedTime && !bookingRef && timer &&
                        <Timer time={timer} business={business} />
                    }
                    {!booked && isNullOrWhitespace(paymentIntent) &&
                        <TransitionWrapper>
                            <Transition active={!!selectedTime}>
                                <YourDetails style={selectedTime && !showExperienceSelection ? { maxHeight: '2rem', overflowY: 'hidden' } : undefined}>
                                    {!showExperienceSelection &&
                                        <InfoForm>
                                            {business?.specialEvents && Object.keys(business?.specialEvents).length > 0 &&
                                                <BookingTabs>
                                                    <BookingTab widgetTheme={business?.theme} selected={selectedTab == 0} onClick={() => changeTab(0)}>Book</BookingTab>
                                                    <BookingTab widgetTheme={business?.theme} selected={selectedTab == 1} onClick={() => changeTab(1)}>Explore events*</BookingTab>
                                                </BookingTabs>
                                            }
                                            {selectedTab == 1 &&
                                                <>
                                                    {!nextAvail && !nextAvailLoading &&
                                                        <>
                                                            {Object.keys(business?.specialEvents).length > 0 &&
                                                                <>
                                                                    <SectionTitle widgetTheme={business?.theme}>Our events</SectionTitle>
                                                                    <FormRow widgetTheme={business?.theme}>
                                                                        <StyledDropdown
                                                                            icon='users'
                                                                            model='guests'
                                                                            value={eventGuests}
                                                                            addDefault={false}
                                                                            items={generatePeopleList(maxPeopleForEvents())}
                                                                            onChange={(e) => setEventGuests(+e.target.value)}
                                                                            unlink
                                                                        />
                                                                    </FormRow>
                                                                    <br />
                                                                </>
                                                            }
                                                            {business?.specialEvents && Object.keys(business?.specialEvents).map(key => (
                                                                <BookingExperienceOption
                                                                    id={+key}
                                                                    key={'event-' + key}
                                                                    disabled={!isNullOrWhitespace(previewLocation)}
                                                                    layout={business?.specialEvents[key].layout}
                                                                    business={business}
                                                                    title={business?.specialEvents[key].name}
                                                                    description={business?.specialEvents[key].description}
                                                                    image={business?.specialEvents[key].imageUrl}
                                                                    price={business?.specialEvents[key].price}
                                                                    onSearch={() => nextAvailSearch(+key,)}
                                                                />
                                                            ))}
                                                            {business?.experiences && Object.keys(business?.experiences).length > 0 &&
                                                                <SectionTitle widgetTheme={business?.theme}>Our experiences</SectionTitle>
                                                            }
                                                            {business?.experiences && Object.keys(business?.experiences).map(key => (
                                                                <BookingExperienceOption
                                                                    id={+key}
                                                                    key={'event-' + key}
                                                                    business={business}
                                                                    layout={business?.experiences[key].layout}
                                                                    title={business?.experiences[key].name}
                                                                    image={business?.experiences[key].imageUrl}
                                                                    description={business?.experiences[key].description}
                                                                    price={business?.experiences[key].price}
                                                                />
                                                            ))}
                                                        </>
                                                    }
                                                    {nextAvailLoading && <Loader />}

                                                    {nextAvail && !loading && !nextAvailLoading &&
                                                        <>
                                                            {nextAvail && searchableEvent &&
                                                                <div style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                                                                    <br />
                                                                    <strong>Next availability for {business?.specialEvents[searchableEvent].name}</strong>
                                                                </div>
                                                            }
                                                            <FormRow widgetTheme={business?.theme}>
                                                                <StyledDropdown
                                                                    icon='users'
                                                                    model='guests'
                                                                    value={eventGuests}
                                                                    addDefault={false}
                                                                    items={generatePeopleList(maxPeopleForEvents())}
                                                                    onChange={(e) => {
                                                                        if (+e.target.value != eventGuests) nextAvailSearch(searchableEvent, +e.target.value)
                                                                    }}
                                                                    unlink
                                                                />
                                                            </FormRow>
                                                            <ButtonBack style={{ marginTop: '1rem', marginBottom: '1rem' }} type='button' widgetTheme={business?.theme} onClick={() => setNextAvail(undefined)}>
                                                                <Icon name='chevron-left' /> Back
                                                            </ButtonBack>
                                                            {eventBreaksGuestRules &&
                                                                <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>
                                                                    For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                                                                </InfoMessage>
                                                            }
                                                            {!eventBreaksGuestRules && nextAvail.availability?.length === 0 &&
                                                                <>
                                                                    <InfoMessage widgetTheme={business?.theme}>
                                                                        There is no availability{searchableEvent && <> for <strong>{business?.specialEvents[searchableEvent].name}</strong></>} in the near future. Please check again later as times are released.
                                                                    </InfoMessage>
                                                                </>
                                                            }
                                                            {!eventBreaksGuestRules && nextAvail.availability.map((slot, index) => (
                                                                <>
                                                                    <InfoMessage widgetTheme={business?.theme}>
                                                                        <strong>{formatDate(moment(slot.date), 'dddd, Do MMMM YYYY')}</strong>
                                                                    </InfoMessage>
                                                                    <br />
                                                                    <TimeRow key={'next-avil-' + index}>
                                                                        {slot && Object.keys(slot.shiftLabelAndTimes).map(shiftLabel => (
                                                                            <>
                                                                                {nextAvailHasMultipleShifts &&
                                                                                    <Column size={12}>
                                                                                        <strong>{shiftLabel}</strong>
                                                                                    </Column>
                                                                                }
                                                                                {slot.shiftLabelAndTimes[shiftLabel].slots.map(time => (
                                                                                    <Column size={desktopSize} tablet={tabletSize} mobile={mobileSize} key={`next-avail-${index}-${time.time}`}>
                                                                                        <ButtonTime
                                                                                            onClick={() => {
                                                                                                setFormData({ ...formData, date: moment(slot.date) });
                                                                                                setSelectedTimeDropdown(time.time)
                                                                                                hold(moment(slot.date), time.time, time)
                                                                                            }}
                                                                                            type='button'
                                                                                            widgetTheme={business?.theme}>
                                                                                            {moment(time.time).format(TIMEFORMAT)}
                                                                                        </ButtonTime>
                                                                                    </Column>
                                                                                ))}
                                                                            </>
                                                                        ))}
                                                                    </TimeRow>
                                                                </>
                                                            ))}
                                                        </>
                                                    }
                                                </>
                                            }
                                            <div style={selectedTab == 1 ? { opacity: 0, height: 0, overflow: 'hidden' } : undefined}>
                                                <FormWrapper onUpdate={(newData) => loading ? {} : setFormData({ ...(nextAvail ? formData : newData) })}>
                                                    {({ id, valid }) => (
                                                        <>
                                                            {!loading &&
                                                                <FormRow widgetTheme={business?.theme}>
                                                                    <StyledDropdown icon='users' model='guests' value={formData.guests} addDefault={false} items={generatePeopleList(business.maximumBookableParty || 20)} onChange={() => setNextAvail(undefined)} />
                                                                </FormRow>
                                                            }
                                                            {partyTooBig && !loading &&
                                                                <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>
                                                                    For a party of this size please contact us {isNullOrWhitespace(business.phoneNumber) ? 'directly' : `on ${business.phoneNumber}`} to make this booking.
                                                                </InfoMessage>
                                                            }
                                                            {business?.experiences && !loading && Object.keys(business?.experiences).length > 0 &&
                                                                <ExperienceOptionContainer>
                                                                    <ExperienceOption
                                                                        widgetTheme={business?.theme}
                                                                        selected={!searchableExperience}
                                                                        onClick={() => onUpdateSearchableExperience(undefined)}>
                                                                        Standard booking
                                                                    </ExperienceOption>
                                                                    {Object.keys(business?.experiences).map((key, index) => (
                                                                        <ExperienceOption
                                                                            widgetTheme={business?.theme}
                                                                            selected={searchableExperience == +key}
                                                                            last={index == Object.keys(business?.experiences).length - 1}
                                                                            onClick={() => onUpdateSearchableExperience(+key)}>
                                                                            {business?.experiences[key].name}
                                                                        </ExperienceOption>
                                                                    ))}
                                                                </ExperienceOptionContainer>
                                                            }
                                                            {!loading && !nextAvailLoading && !nextAvail && !partyTooBig &&
                                                                <>
                                                                    {!bookingReference && business.firstAvailableDate && createMomentFromValue(business.firstAvailableDate).format(DEFAULT_DATE_FORMAT) !== moment().format(DEFAULT_DATE_FORMAT) &&
                                                                        <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>
                                                                            The first available date has been pre-selected.
                                                                        </InfoMessage>
                                                                    }
                                                                    <StyledCalendar onChange={dateChange} inline placeholder='Select date' model='date' value={formData.date} months={1} calendarProps={{
                                                                        filterDate: isValidDate,
                                                                        highlightDates: business.specialEventDates && Object.keys(business.specialEventDates).length > 0 ? [
                                                                            {
                                                                                'datepicker__event-day': specialEventDates.current
                                                                            }
                                                                        ] : undefined
                                                                    }} />
                                                                </>
                                                            }

                                                            {nextAvailLoading && <Loader />}

                                                            {nextAvail && !loading && !nextAvailLoading &&
                                                                <>
                                                                    {nextAvail.availability?.length > 0 && searchableExperience &&
                                                                        <div style={{ textAlign: 'center' }}>
                                                                            <br />
                                                                            <strong>Next availability for {business?.experiences[searchableExperience].name}</strong>
                                                                        </div>
                                                                    }
                                                                    <ButtonBack style={{ marginTop: '1rem', marginBottom: '1rem' }} type='button' widgetTheme={business?.theme} onClick={() => setNextAvail(undefined)}>
                                                                        <Icon name='chevron-left' /> Back
                                                                    </ButtonBack>
                                                                    {nextAvail.availability?.length === 0 &&
                                                                        <>
                                                                            <InfoMessage widgetTheme={business?.theme}>
                                                                                There is no availability{searchableExperience && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} in the near future. Please check again later as times are released.
                                                                            </InfoMessage>
                                                                        </>
                                                                    }
                                                                    {nextAvail.availability.map((slot, index) => (
                                                                        <>
                                                                            <InfoMessage widgetTheme={business?.theme}>
                                                                                <strong>{formatDate(moment(slot.date), 'dddd, Do MMMM YYYY')}</strong>
                                                                            </InfoMessage>
                                                                            <br />
                                                                            <TimeRow key={'next-avil-' + index}>
                                                                                {slot && Object.keys(slot.shiftLabelAndTimes).map(shiftLabel => (
                                                                                    <>
                                                                                        {nextAvailHasMultipleShifts &&
                                                                                            <Column size={12}>
                                                                                                <strong>{shiftLabel}</strong>
                                                                                            </Column>
                                                                                        }
                                                                                        {slot.shiftLabelAndTimes[shiftLabel].slots.map(time => (
                                                                                            <Column size={desktopSize} tablet={tabletSize} mobile={mobileSize} key={`next-avail-${index}-${time.time}`}>
                                                                                                <ButtonTime
                                                                                                    onClick={() => {
                                                                                                        setFormData({ ...formData, date: moment(slot.date) });
                                                                                                        setSelectedTimeDropdown(time.time)
                                                                                                        hold(moment(slot.date), time.time, time)
                                                                                                    }}
                                                                                                    type='button'
                                                                                                    widgetTheme={business?.theme}>
                                                                                                    {moment(time.time).format(TIMEFORMAT)}
                                                                                                </ButtonTime>
                                                                                            </Column>
                                                                                        ))}
                                                                                    </>
                                                                                ))}
                                                                            </TimeRow>
                                                                        </>
                                                                    ))}
                                                                </>
                                                            }

                                                            {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && hasTimeSlots &&
                                                                <>
                                                                    <FormRow widgetTheme={business?.theme}>
                                                                        <StyledDropdown icon='clock' value={selectedTimeDropdown} items={timeOptions} onChange={(e) => setSelectedTimeDropdown(e.target.value)} defaultText='Please select a time' />
                                                                    </FormRow>
                                                                    {bookingError && !bookingRef &&
                                                                        <>
                                                                            <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>{bookingError}</InfoMessage>
                                                                        </>
                                                                    }
                                                                    <Button disabled={isNullOrWhitespace(selectedTimeDropdown) || !isNullOrWhitespace(previewLocation)} widgetTheme={business?.theme} type='button' onClick={() => hold()}>
                                                                        {isNullOrWhitespace(business.reserveButtonTextStepOne) ? 'Reserve' : business.reserveButtonTextStepOne}
                                                                    </Button>

                                                                </>
                                                            }
                                                            {!nextAvail && !partyTooBig && !loading && !nextAvailLoading && times && !hasTimeSlots &&
                                                                <div>
                                                                    <InfoMessage widgetTheme={business?.theme}>
                                                                        No availability{searchableExperience && <> for <strong>{business?.experiences[searchableExperience].name}</strong></>} found on this day.
                                                                    </InfoMessage>
                                                                    <ButtonOutline widgetTheme={business?.theme} type='button' onClick={() => nextAvailSearch(searchableExperience)}>
                                                                        <Icon name='search' /> Search next available
                                                                    </ButtonOutline>
                                                                </div>
                                                            }
                                                            {loading && <Loader />}
                                                        </>
                                                    )}
                                                </FormWrapper>
                                            </div>
                                        </InfoForm>
                                    }
                                    {showExperienceSelection &&
                                        <>
                                            <BookingQuickInfoTwo>
                                                <BookingQuickInfoItem expand>
                                                    <Icon name='calendar' /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}
                                                </BookingQuickInfoItem>
                                                <BookingQuickInfoItem>
                                                    <Icon name='clock' /> {moment(selectedTimeForExperienceSelection).format(TIMEFORMAT)}
                                                </BookingQuickInfoItem>
                                                <BookingQuickInfoItem>
                                                    <Icon name='user-friends' /> {formData.guests}
                                                </BookingQuickInfoItem>
                                            </BookingQuickInfoTwo>
                                            <SubTitle widgetTheme={business?.theme}>Please select an option:</SubTitle>
                                            <BookingExperienceOption
                                                onSelect={eventsAvailabilityResponse.standardAvailable ? updateSelectedExperience : undefined}
                                                id={-1}
                                                isSelected={selectedExperience == -1}
                                                business={business}
                                                layout={ExperienceWidgetLayout.Top}
                                                unavailable={!eventsAvailabilityResponse.standardAvailable}
                                                title='Standard booking'
                                                description={''}
                                                price={0}
                                            />
                                            {Object.keys(business.specialEvents).map(experienceId => {
                                                if (!eventsAvailabilityResponse.availableIds[experienceId]) return null;
                                                return (
                                                    <BookingExperienceOption
                                                        id={+experienceId}
                                                        onSelect={eventsAvailabilityResponse.availableIds[experienceId] ? updateSelectedExperience : undefined}
                                                        isSelected={selectedExperience == +experienceId}
                                                        key={'select-event-' + experienceId}
                                                        business={business}
                                                        layout={business?.specialEvents[experienceId].layout}
                                                        unavailable={!eventsAvailabilityResponse.availableIds[experienceId]}
                                                        title={business?.specialEvents[experienceId].name}
                                                        image={business?.specialEvents[experienceId].imageUrl}
                                                        description={business?.specialEvents[experienceId].description}
                                                        price={business?.specialEvents[experienceId].price}
                                                    />
                                                )
                                            })}
                                            {Object.keys(eventsAvailabilityResponse.availableIds).map(experienceId => {
                                                const matchingExpeirence = business.experiences[experienceId];
                                                if (!matchingExpeirence || matchingExpeirence.type != ExperienceType.Experience) return null;
                                                return (
                                                    <BookingExperienceOption
                                                        id={+experienceId}
                                                        onSelect={updateSelectedExperience}
                                                        isSelected={selectedExperience == +experienceId}
                                                        key={'select-event-' + experienceId}
                                                        business={business}
                                                        layout={business?.experiences[experienceId].layout}
                                                        title={business?.experiences[experienceId].name}
                                                        image={business?.experiences[experienceId].imageUrl}
                                                        description={business?.experiences[experienceId].description}
                                                        price={business?.experiences[experienceId].price}
                                                    />
                                                )
                                            })}
                                            {bookingError &&
                                                <>
                                                    <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>{bookingError}</InfoMessage>
                                                </>
                                            }
                                            <Button disabled={!selectedExperience} widgetTheme={business?.theme} type='button' onClick={() => confirmHold()}>
                                                Confirm selection
                                            </Button>
                                        </>
                                    }
                                </YourDetails>
                                {loading && selectedTime &&
                                    <YourDetails>
                                        <Loader />
                                    </YourDetails>
                                }

                                {!loading && loaded && times && !false &&
                                    <YourDetails>
                                        <BookingQuickInfoTwo>
                                            <BookingQuickInfoItem expand>
                                                <Icon name='calendar' /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}
                                            </BookingQuickInfoItem>
                                            <BookingQuickInfoItem>
                                                <Icon name='clock' /> {moment(selectedTime).format(TIMEFORMAT)}
                                            </BookingQuickInfoItem>
                                            <BookingQuickInfoItem>
                                                <Icon name='user-friends' /> {formData.guests}
                                            </BookingQuickInfoItem>
                                            {experienceTypeShouldShow(business) && <>
                                                <br />
                                                <BookingQuickInfoItem expand>
                                                    <Icon name='utensils' /> {
                                                        getSelectedExperienceName(selectedExperience, business)
                                                    }
                                                </BookingQuickInfoItem>
                                            </>}
                                        </BookingQuickInfoTwo>
                                        <FormWrapper onUpdate={setDetails}>
                                            {({ id, valid }) => (
                                                <>
                                                    {(!bookingErrors || bookingErrors.length === 0) &&
                                                        <>
                                                            <Row hidden={!!!selectedTime}>
                                                                {!bookingHash &&
                                                                    <>
                                                                        <Column size={6} mobile={12}>
                                                                            <StyledTextInput required label='First name' model='firstName' max={200} value={details.firstName} />
                                                                        </Column>
                                                                        <Column size={6} mobile={12}>
                                                                            <StyledTextInput required label='Last name' model='lastName' max={200} value={details.lastName} />
                                                                        </Column>
                                                                        <Column size={6} mobile={12}>
                                                                            <StyledTextInput required label='Telephone' model='telephone' max={20} value={details.telephone} regexMatch={TELEPHONE_REGEX} regexError='Value must be a telephone number' />
                                                                        </Column>
                                                                        <Column size={6} mobile={12}>
                                                                            <StyledTextInput required label='Email' max={320} model='email' value={details.email} regexMatch={EMAIL_REGEX} regexError='Value must be a valid email address' />
                                                                        </Column>
                                                                    </>
                                                                }
                                                                <Column size={12} mobile={12}>
                                                                    <StyledTextarea label='Special requests' maxLength={600} model='specialRequests' value={details.specialRequests} />
                                                                </Column>
                                                                {specialOccasionOptions.length > 0 &&
                                                                    <Column size={12} mobile={12}>
                                                                        <StyledDropdown label='Select an occasion' model='specialOccasion' value={details.specialOccasion} items={specialOccasionOptions} />
                                                                    </Column>
                                                                }
                                                                {!bookingHash && business?.customFields?.map((field, index) => (
                                                                    <Column size={12} mobile={12} key={`Custom-${index}`}>
                                                                        {field.type == EnumWidgetFieldType.Text && <StyledTextInput required={field.required} label={field.label} model={`Custom-${index}`} value={details[`Custom-${index}`]} />}
                                                                        {field.type == EnumWidgetFieldType.Number && <StyledNumberInput required={field.required} label={field.label} model={`Custom-${index}`} value={details[`Custom-${index}`]} />}
                                                                        {field.type == EnumWidgetFieldType.Dropdown && <StyledDropdown required={field.required} label={field.label} value={details[`Custom-${index}`]} items={field.values.split('|').map(x => ({ value: x }))} model={`Custom-${index}`} />}
                                                                    </Column>
                                                                ))}
                                                                {!bookingHash &&
                                                                    <Column size={12} mobile={12} noMarginBottom>
                                                                        <Checkbox model='updates' checked={details.updates} label={`Yes, I would like to receive updates and promotions from ${business.name}.`} />
                                                                    </Column>
                                                                }
                                                            </Row>
                                                            {holdDetails?.depositRequired && !holdDetails?.takeDepositNow &&
                                                                <>
                                                                    <InfoMessage widgetTheme={business?.theme}>No show charges apply for this booking. You will be asked on the next screen for your card details. The no show charge for this booking is: £{holdDetails.depositAmount.toFixed(2)}</InfoMessage>
                                                                </>
                                                            }
                                                            {holdDetails?.depositRequired && holdDetails?.takeDepositNow &&
                                                                <>
                                                                    <InfoMessage widgetTheme={business?.theme}>A deposit is required for this booking. You will be asked on the next screen for your card details. The deposit for this booking is: £{holdDetails.depositAmount.toFixed(2)}</InfoMessage>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {bookingErrors && bookingErrors.length > 0 &&
                                                        <>
                                                            <br />
                                                            {bookingErrors.map((e, index) => (
                                                                <InfoMessage widgetTheme={business?.theme} key={`Error-${index}`}>
                                                                    <Icon name='triangle-exclamation' /> {e.message}
                                                                </InfoMessage>
                                                            ))}
                                                        </>
                                                    }
                                                    {bookingError && !bookingHash && (!bookingErrors || bookingErrors.length == 0) &&
                                                        <>
                                                            <br />
                                                            <InfoMessage widgetTheme={business?.theme} key={`Error`}>{bookingError}</InfoMessage>
                                                        </>
                                                    }
                                                    <Button disabled={!valid || !isNullOrWhitespace(bookingError) || (bookingErrors && bookingErrors.length > 0)} style={{ marginTop: '1.5rem' }} widgetTheme={business?.theme} type='button' onClick={() => takeClientDetails()}>
                                                        {bookingHash ? 'Confirm amendment' : isNullOrWhitespace(business.reserveButtonTextStepTwo) ? 'Complete booking' : business.reserveButtonTextStepTwo}
                                                    </Button>
                                                    {bookingError && bookingHash &&
                                                        <>
                                                            <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>{bookingError}</InfoMessage>
                                                        </>
                                                    }
                                                </>
                                            )}
                                        </FormWrapper>
                                        <div style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
                                            **By selecting “{isNullOrWhitespace(business.reserveButtonTextStepTwo) ? 'Complete booking' : business.reserveButtonTextStepTwo}” you are agreeing to the terms and conditions of the Dish Forager User Agreement and Privacy Policy.
                                        </div>
                                    </YourDetails>
                                }
                            </Transition>
                        </TransitionWrapper>
                    }
                    {!isNullOrWhitespace(paymentIntent) &&
                        <>
                            {holdDetails?.depositRequired && !holdDetails?.takeDepositNow &&
                                <>
                                    <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>
                                        No show charges apply for this booking. The no show charge for this booking is: £{holdDetails.depositAmount.toFixed(2)}. Your card will only be charged if you don't attend your booking or fail to cancel you booking in accordance with the cancellation policy of the venue.
                                    </InfoMessage>
                                </>
                            }
                            {holdDetails?.depositRequired && holdDetails?.takeDepositNow &&
                                <>
                                    <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>
                                        A deposit is required for this booking. The deposit for this booking is: £{holdDetails.depositAmount.toFixed(2)}
                                    </InfoMessage>
                                    <br />
                                </>
                            }
                            <InfoMessage style={{ marginTop: '0.5rem' }} widgetTheme={business?.theme}>
                                By completing the the payment, you authorise {Constants.businessName} and {business.name} to send instructions to the financial institution that issued your card to take payments from your card account in accordance with the terms of {business.name}.
                            </InfoMessage>
                            <Checkout
                                intentSecret={paymentIntent}
                                payNow={holdDetails.takeDepositNow}
                                accountId={holdDetails.accountId}
                                widgetTheme={business?.theme}
                                returnUrl={window.location.href + `?holdRef=${holdDetails.holdReference}&takeNow=${holdDetails?.takeDepositNow ? 'true' : 'false'}`}
                                widget
                            />
                            <br />
                        </>
                    }
                    {(booked) &&
                        <div style={{ textAlign: 'center' }}>
                            <Checkmark widgetTheme={business?.theme}><Icon name='check' /></Checkmark>
                            <ThankYou>
                                Thank you for booking with {business.name}!
                                <br />
                            </ThankYou>
                            <p style={{ marginBottom: '0.5rem' }}>
                                Booking reference: <strong>{bookingResponse.bookingReference}</strong>
                            </p>
                            <div>
                                <Icon name='calendar' /> {moment(formData.date).format(DATE_WITH_DAY_OF_WEEK_FORMAT)}<br />
                                <Icon name='clock' /> {moment(selectedTime).format(TIMEFORMAT)}<br />
                                <Icon name='user-friends' /> {formData.guests} guest{formData.guests > 1 && 's'}<br />
                                {experienceTypeShouldShow(business) &&
                                    <>
                                        <Icon name='utensils' />
                                        {getSelectedExperienceName(selectedExperience, business)}
                                    </>
                                }
                            </div>
                            <br />
                            <strong>Please note, you can manage this booking via the link in your confirmation email.</strong>
                            <br />
                            <br />
                            <strong>Incorrect details?</strong>
                            <br />
                            <br />
                            <ManageMyBookingLink widgetTheme={business?.theme} href={`https://dishforager.com/manage-my-booking/${routeParams.location}/${bookingResponse.bookingReference}/${bookingResponse.bookingUniqueId}`}>Amend your booking</ManageMyBookingLink>
                            {business.bookingConfirmationEmailText &&
                                <p style={{ whiteSpace: 'pre-line' }}>
                                    <br />
                                    <br />
                                    {business.bookingConfirmationEmailText}
                                </p>
                            }
                            <br />
                            <br />
                        </div>
                    }
                    {!bookingHash &&
                        <PoweredBy onClick={() => window.top.open('https://www.dishforager.com/', '_blank')} widgetTheme={business?.theme}>
                            Powered by
                            <br />
                            <Logo title={Constants.businessName} widgetTheme={business?.theme}><SVG /></Logo>
                        </PoweredBy>
                    }
                </>
            }
        </BookingModuleContainer>
    );
};

const SVG = () =>
    <svg version="1.1" id="Layer_2_00000008142285360707904690000010961109162998869938_"
        xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 928.6 99.9"
        xmlSpace="preserve">
        <style type="text/css">
            {`.st0{fill:#EFEFEF;}
	.st1{fill:#FFFFFF;}
	.st2{fill:none;stroke:#FFFFFF;stroke-width:3;stroke-miterlimit:10;}`}
        </style>
        <g id="Layer_1-2">
            <g>
                <path className="st0" d="M0,98.1l2-33.6L3.9,9.4C4,7.3,4,4.7,4,1.5l15.6,0.1l19.4-0.1c9.1,0,16.4,0.7,21.9,2.1s10.4,4.3,14.7,8.7
			c4.3,4.4,7.3,9.4,9.1,15c1.8,5.6,2.7,11.6,2.7,18.1c0,7.7-1.4,15.1-4.1,22.3s-6.8,13.2-12.2,18s-11.5,8.1-18.3,9.8
			s-14.3,2.6-22.3,2.6l-15-0.1C15.4,98.1,0,98.1,0,98.1z M6.1,93.7c8.4,0.1,14.9,0.2,19.8,0.2s9.7-0.2,14.7-0.7
			c5-0.5,9.5-1.3,13.5-2.6s7.8-3.5,11.7-6.7S72.6,77,75,73c2.4-4,4.2-8.5,5.4-13.4s1.8-9.8,1.8-14.8s-0.6-9.5-1.7-13.9
			c-0.8-3.2-2-6.2-3.7-9c-1.8-3.2-4-5.9-6.4-8c-2.4-2.2-5-3.8-7.6-5s-6-1.9-9.9-2.4C49.3,6,45.1,5.8,40.3,5.7H23.4L9.8,5.8L6.1,93.7
			L6.1,93.7z"/>
                <path className="st0" d="M104.6,98.1l1.6-28.4l1.4-42.5l0.3-14.7c0.1-3.9,0.1-7.6,0.1-11c1.2,0,2.3,0.1,3.2,0.1c0.9,0,2,0,3.2-0.1
			L113,27.1l-1.7,52.7L111,98.1c-1.2-0.1-2.2-0.1-3.2-0.1S105.8,98.1,104.6,98.1L104.6,98.1z"/>
                <path className="st0" d="M129.6,95.2c0-1.8-0.1-4-0.4-6.4c8.9,4.2,17.1,6.4,24.5,6.4c4.2,0,8-0.7,11.4-2.1c3.4-1.4,6-3.4,7.9-6.1
			c1.9-2.7,2.8-5.8,2.8-9.3c0-1.7-0.3-3.4-0.9-4.9c-0.6-1.6-1.6-3.2-2.9-4.8c-1.3-1.7-3.3-3.8-5.8-6.3c-2.5-2.6-7-6.6-13.6-12
			l-5.6-4.7c-1.9-1.7-3.8-3.5-5.6-5.3c-1.8-1.8-3.3-3.5-4.5-5.1c-1.2-1.6-2.1-3-2.7-4.2s-1.1-2.6-1.4-4.1c-0.3-1.5-0.5-3.1-0.5-4.7
			c0-3.8,1-7.4,3.1-10.7s5.2-5.9,9.3-7.9C149,1,154.2,0,160.5,0c4.4,0,10,0.6,16.8,1.9c0.1,1.8,0.3,3.8,0.6,5.9
			c-4.4-1.4-8-2.3-10.9-2.8s-5.6-0.7-8.2-0.7c-4.7,0-8.5,0.8-11.6,2.3c-3,1.5-5.4,3.6-7,6.2c-1.6,2.6-2.5,5.3-2.5,8.1
			c0,1.5,0.2,3,0.7,4.4s1.2,2.9,2.3,4.4c1.1,1.5,2.5,3.1,4.3,4.9c1.8,1.8,3.9,3.8,6.4,6.1c2.4,2.3,5.7,5.2,9.8,8.7
			c4.1,3.5,6.9,6.1,8.6,7.5c1.7,1.5,3.3,3.1,4.8,4.8c1.5,1.7,2.7,3.4,3.7,5.1c0.9,1.7,1.6,3.4,2.1,5.2c0.4,1.8,0.7,3.7,0.7,5.7
			c0,4.4-1.1,8.3-3.4,11.7s-5.6,5.9-9.9,7.6c-4.4,1.7-9.3,2.6-14.7,2.6c-3.5,0-6.9-0.3-10.2-0.8C139.7,98.3,135.2,97.1,129.6,95.2
			L129.6,95.2z"/>
                <path className="st0" d="M198.9,98.1c1.1-20.3,1.9-39.3,2.5-57s0.8-30.9,0.8-39.7c1.2,0,2.3,0.1,3.1,0.1s1.9,0,3.1-0.1l-1.9,45.2h65.8
			l1.3-35.5c0.1-2,0.1-5.2,0.1-9.7c1.2,0,2.3,0.1,3.1,0.1s1.9,0,3.1-0.1c-1.8,29.3-3,61.5-3.8,96.6c-1.2-0.1-2.2-0.1-3.1-0.1
			c-0.9,0-2,0-3.1,0.1c1.1-16.4,1.8-32.1,2.1-47.1h-65.8l-1.1,34.8l-0.1,12.3c-1.2-0.1-2.3-0.1-3.1-0.1
			C201.1,98.1,200,98.1,198.9,98.1L198.9,98.1z"/>
                <path className="st1" d="M336.7,98.1l1.3-24.6l1.5-47.4l0.3-14c0-3.4,0.1-7,0.1-10.6c9.9,0,19.3,0.1,28.3,0.1l24.6-0.1
			c-0.2,1.9-0.3,3.6-0.3,5c-4.1-0.4-8.6-0.6-13.7-0.6s-9.7-0.1-13.8-0.1h-19.5l-1.3,40.8l15.1,0.2c12.8,0,22.4-0.1,28.6-0.2
			c-0.2,1.4-0.3,3-0.4,4.9l-12.6-0.1c-5-0.1-8.9-0.1-11.8-0.1c-6.1,0-12.5,0.1-19.2,0.3c-0.6,20.3-0.9,35.8-0.9,46.7
			c-1.2-0.1-2.2-0.1-3.1-0.1S337.9,98.1,336.7,98.1z"/>
                <path className="st1" d="M457.2,0c6.8,0,13.1,1,19.1,3c6,2,11.3,5.3,15.8,9.9s7.7,9.8,9.6,15.8s2.9,12.2,2.9,18.9
			c0,7.3-1.1,14.3-3.3,21.1s-5.5,12.4-9.8,17c-4.4,4.6-9.9,8.1-16.5,10.4c-6.6,2.4-13.5,3.5-20.7,3.5s-13.6-1.1-20-3.3
			c-6.4-2.2-11.7-5.4-16-9.7c-4.3-4.3-7.5-9.4-9.5-15.4c-2-6-3-12.3-3-18.9c0-7.4,1.2-14.6,3.7-21.6c2.5-7,6-12.7,10.7-17.3
			s10.2-7.9,16.7-10.1C443.3,1.1,450.1,0,457.2,0L457.2,0z M456.9,4.4c-6.5,0-12.7,1-18.4,3s-10.8,5.3-15,9.8s-7.4,9.8-9.4,15.9
			s-3,12.5-3,19.2c0,6.3,1,12.3,3.1,18.1c2.1,5.8,5.1,10.5,9.1,14.3c4,3.8,8.7,6.5,14.1,8.1s11.1,2.5,17.1,2.5
			c6.3,0,12.3-0.9,18-2.8c5.6-1.8,10.5-4.9,14.7-9.2c4.1-4.3,7.2-9.4,9.1-15.5s2.9-12.4,2.9-19.1s-0.9-12.4-2.7-18.2
			s-4.6-10.7-8.5-14.7c-3.9-4-8.6-6.9-14-8.7C468.7,5.3,463,4.4,456.9,4.4L456.9,4.4z"/>
                <path className="st1" d="M521.7,98.1l1.1-17.1l2-53.3l0.3-15.1l0.1-11l14.1,0.1l16.2-0.1c6.7,0,11.6,0.9,14.7,2.7
			c3.1,1.8,5.5,4.3,7.3,7.6s2.7,7,2.7,11.3c0,5.3-1.3,10.1-4,14.5s-6.3,7.8-10.9,10.1s-9.9,3.5-15.9,3.5c-0.8,0-1.9,0-3.4-0.1
			l33.9,47.1c-1.2-0.1-2.4-0.1-3.7-0.1s-2.5,0-3.7,0.1l-9.3-14L536,46c4.2,0.8,7.8,1.1,10.9,1.1c3.7,0,7.3-0.5,10.8-1.5
			c3.5-1,6.5-2.5,9-4.6s4.4-4.6,5.8-7.6c1.4-3,2.1-6.4,2.1-10c0-2.4-0.3-4.7-1-6.7s-1.7-3.9-3.2-5.5c-1.5-1.6-3-2.7-4.5-3.4
			s-3.5-1.2-5.9-1.4c-2.4-0.3-5-0.5-7.7-0.5L540.8,6c-2.5,0-5.8-0.1-9.8-0.1c-1.6,37.4-2.5,68.1-2.7,92.3c-1.2-0.1-2.3-0.1-3.3-0.1
			S522.9,98.1,521.7,98.1L521.7,98.1z"/>
                <path className="st1" d="M582.3,98.1L606,51.6l20.9-41.2l4.3-8.9c1.2,0,2.5,0.1,3.8,0.1s2.7,0,3.8-0.1l13.7,38.9l20.9,57.7
			c-1.2-0.1-2.3-0.1-3.2-0.1c-1,0-2,0-3.2,0.1c-1.3-4.5-3.8-12-7.4-22.5c-3.6-10.4-6.5-18.3-8.6-23.7h-40l-22.3,46.2
			c-1.2-0.1-2.3-0.1-3.2-0.1S583.4,98.1,582.3,98.1L582.3,98.1z M613.3,47.4h36.3L634.5,5.9L613.3,47.4z"/>
                <path className="st1" d="M742.3,44.9c8.6,0,15,0.1,19,0.1l12.4-0.1l-1.2,22.4l-0.8,27.3c-7.4,1.8-14.1,3.1-20,3.9
			c-5.9,0.8-11,1.2-15.1,1.2c-7.2,0-14.1-1-20.7-3c-6.6-2-12.2-5.1-16.9-9.2s-8.1-9.2-10.3-15.3s-3.3-12.5-3.3-19.3
			c0-7.8,1.5-15.2,4.3-22.2c2.9-7,6.9-12.8,12-17.5c5.1-4.6,11-8,17.6-10.1C726.1,1,733.3,0,741,0c4.3,0,8.8,0.3,13.3,0.8
			c4.5,0.5,9.8,1.5,15.9,2.8c0.1,2.4,0.3,4.6,0.6,6.5c-6.8-2.2-12.5-3.7-17.3-4.5c-4.8-0.8-9.2-1.2-13.4-1.2c-7,0-13.6,1.1-19.9,3.3
			s-11.7,5.4-16,9.8c-4.4,4.3-7.7,9.7-10,16.1s-3.4,12.9-3.4,19.7c0,6.4,1,12.3,3,17.8c2,5.5,5,10,9,13.4c4,3.5,8.9,6.2,14.7,8
			c5.8,1.9,12.1,2.8,19,2.8c2.4,0,4.9-0.1,7.7-0.4c2.8-0.3,5.6-0.6,8.4-1.1c2.8-0.4,5.1-0.9,6.8-1.3c1.8-0.4,4.1-1.1,7-2l1.7-41.2
			h-8.4c-2.3,0-4.5,0-6.8,0.1l-10.7,0.5c0.1-1.5,0.2-2.8,0.2-4.1L742.3,44.9L742.3,44.9z"/>
                <path className="st1" d="M794.3,98.1c0.9-12.8,1.7-29.3,2.5-49.5c0.7-20.2,1.1-35.9,1.1-47.1c10.5,0,20.3,0.1,29.5,0.1l25.8-0.1
			c-0.2,1.9-0.3,3.6-0.3,5L847.3,6c-1.5-0.2-4-0.2-7.7-0.3c-3.6,0-7.2,0-10.7,0c-11.2,0-19.7,0-25.5,0.1L802,46.4
			c7.1,0.1,13.7,0.2,19.6,0.2c7.1,0,15.8-0.1,26.2-0.2c-0.2,1.4-0.3,3-0.3,4.7l-26.7-0.2c-3.1,0-9.5,0.1-19,0.2l-1.4,42.6l20.3,0.1
			c8,0,13.5,0,16.6-0.1s7.3-0.2,12.6-0.5c-0.2,1.9-0.3,3.6-0.3,5c-11.3-0.1-19.6-0.1-24.8-0.1L794.3,98.1L794.3,98.1z"/>
                <path className="st1" d="M870.3,98.1l1.1-17.1l2-53.3l0.3-15.1l0.1-11l14.1,0.1L904,1.5c6.7,0,11.6,0.9,14.7,2.7
			c3.1,1.8,5.5,4.3,7.3,7.6s2.7,7,2.7,11.3c0,5.3-1.3,10.1-4,14.5s-6.3,7.8-10.9,10.1s-9.9,3.5-15.9,3.5c-0.8,0-1.9,0-3.4-0.1
			l33.9,47.1c-1.2-0.1-2.4-0.1-3.7-0.1s-2.5,0-3.7,0.1l-9.3-14l-27-38.2c4.2,0.8,7.8,1.1,10.9,1.1c3.7,0,7.3-0.5,10.8-1.5
			c3.5-1,6.5-2.5,9-4.6s4.4-4.6,5.8-7.6c1.4-3,2.1-6.4,2.1-10c0-2.4-0.3-4.7-1-6.7s-1.7-3.9-3.2-5.5c-1.5-1.6-3-2.7-4.5-3.4
			s-3.5-1.2-5.9-1.4c-2.4-0.3-5-0.5-7.7-0.5L889.4,6c-2.5,0-5.8-0.1-9.8-0.1c-1.5,37.4-2.5,68.1-2.7,92.3c-1.2-0.1-2.3-0.1-3.3-0.1
			S871.5,98.1,870.3,98.1L870.3,98.1z"/>
                <path className="st1" d="M457.4,9.2c5.4,0,10.5,0.8,15.4,2.4s9,4.3,12.6,7.9s6.2,7.9,7.7,12.6c1.5,4.8,2.3,9.8,2.3,15.1
			c0,5.9-0.9,11.5-2.6,16.9s-4.4,10-7.9,13.6c-3.5,3.7-7.9,6.5-13.2,8.3s-10.8,2.8-16.6,2.8s-10.9-0.9-16-2.6s-9.4-4.3-12.8-7.8
			c-3.5-3.4-6-7.6-7.6-12.4c-1.6-4.8-2.4-9.9-2.4-15.2c0-6,1-11.7,3-17.3s4.8-10.2,8.5-13.9c3.7-3.7,8.2-6.4,13.4-8.1
			S451.8,9.2,457.4,9.2L457.4,9.2z M457.2,12.7c-5.2,0-10.2,0.8-14.8,2.4c-4.6,1.6-8.6,4.2-12,7.8c-3.4,3.6-5.9,7.9-7.5,12.8
			c-1.6,4.9-2.4,10-2.4,15.4c0,5,0.8,9.9,2.5,14.5s4.1,8.4,7.3,11.5c3.2,3,7,5.2,11.3,6.5c4.3,1.3,8.9,2,13.7,2
			c5.1,0,9.9-0.7,14.4-2.2s8.5-3.9,11.8-7.4c3.3-3.4,5.7-7.6,7.3-12.4c1.5-4.8,2.3-9.9,2.3-15.3s-0.7-10-2.1-14.6s-3.7-8.5-6.9-11.8
			c-3.1-3.2-6.9-5.6-11.2-7S462.1,12.7,457.2,12.7L457.2,12.7z"/>
                <path className="st2" d="M411.3,74.2l-31.9,16.9c-1,0.6-1.8,1.4-1.5,1.9l2.3,5.2c0.2,0.3,1,0.3,1.8-0.2l35.1-19.8" />
            </g>
        </g>
    </svg>


const Timer = ({ time, business }: { time: Moment, business: any }) => {
    const timeRef = useRef(time)
    const [secondsRemaining, setSecondsRemaining] = useState(timeRef.current.diff(moment(), 'seconds'))
    const timeLeft = secondsToTime(secondsRemaining);

    if (secondsRemaining > 0) setTimeout(() => {
        setSecondsRemaining(secondsRemaining - 1);
    }, 1000);

    if (secondsRemaining < 1) return (
        <>
            <InfoMessage widgetTheme={business?.theme}>
                Your held slot has timed out. You can still try and book but we cannot guarantee your booking.
            </InfoMessage>
            <br />
        </>
    )

    return (
        <>
            <InfoMessage widgetTheme={business?.theme}>
                <Icon name='clock' /> Booking is held for <strong>{timeLeft.m}:{(timeLeft.s < 10 ? '0' : '') + timeLeft.s} minutes</strong>
            </InfoMessage>
            <br />
        </>
    );
};

export default BookingModule;
