import { extendTheme } from '@chakra-ui/react'
import { DefaultTheme } from 'styled-components';
import { HEADING_FONT } from '../constants';

export const ConvertHexToRGBA = (hex: string, opacity: number = 1) => {
    let h: string = hex.replace('#', '');
    let regexMatch = h.match(new RegExp('(.{' + h.length / 3 + '})', 'g'));

    if (regexMatch) for (let i = 0; i < regexMatch.length; i++) {
        regexMatch[i] = parseInt(regexMatch[i].length===1 ? regexMatch[i] + regexMatch[i] : regexMatch[i], 16).toString();
    }

    if (typeof opacity!== 'undefined' && regexMatch) regexMatch.push(opacity.toString());

    if (regexMatch) return regexMatch.join(',');
    return '';
}

export interface ThemeColors {
    primary: string;
    primaryContrast: string;
    secondary: string;
    secondaryContrast: string;
    tertiary: string;
    tertiaryContrast: string;
    headerBackground: string;
    headerText: string;
    background: string;
    borderColor: string;
    negative: string;
    negativeContrast: string;
    positive: string;
    positiveContrast: string;
    warning: string;
    warningContrast: string;
    inputBackground: string;
    placeholderLoaderBackground: string;
}

// Dark blue
const primary = '#002c3d';
const secondary = '#164e64';
const tertiary = '#8d99ae';

// Light blue
// const primary = '#457b9d';
// const secondary = '#1d3557';
// const tertiary = '#a8dadc';

// Pastel blue
// const primary = '#014A7D';
// const secondary = '#4b5c78';
// const tertiary = '#4b5c78';

// OG Green
// const primary = '#00403D';
// const secondary = '#001A1A';
// const tertiary = '#076662';

export const defaultColors: ThemeColors = {
  primary,
  primaryContrast: '#FFFFFF',
  secondary,
  secondaryContrast: '#FFFFFF',
  tertiary,
  tertiaryContrast: '#FFFFFF',
  headerBackground: '#00276a',
  headerText: '#FFF',
  negative: '#de1b35',
  negativeContrast: '#FFFFFF',
  positive: '#3ea63e',
  positiveContrast: '#FFFFFF',
  background: '#f9fafa',
  borderColor: '#d0cece',
  warning: '#ffc409',
  warningContrast: '#000000',
  inputBackground: '#ffffff',
  placeholderLoaderBackground: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
}

// #ffffff05


// #005b6a

const colorDefaults: { light: ThemeColors, dark: ThemeColors } = {
    light: {
        ...defaultColors,
    },
    dark: {
        ...defaultColors,
        background: '#121212'
    }
}

const colors = {
    brand: {
        900: '#1a365d',
        800: '#153e75',
        700: '#2a69ac',
    },
}

const sizes = {
  borderSize: '2px'
}

export const light: DefaultTheme = {
    name: 'Light',
    ...colorDefaults.light,
    ...sizes
}

export const dark: DefaultTheme = {
    name: 'Dark',
    ...colorDefaults.dark,
    ...sizes,
    placeholderLoaderBackground: 'linear-gradient(to right,#2b384c 8%,#1e2a3e 18%,#2b384c 33%)',
}

export const IonicLightTheme = `
  h1 {
    font-size: 2rem !important;
    ${HEADING_FONT}
  }
  h2 {
    font-size: 1.8rem !important;
    ${HEADING_FONT}
  }
  h3 {
    font-size: 1.6rem !important;
    ${HEADING_FONT}
  }
  h4 {
    font-size: 1.4rem !important;
    ${HEADING_FONT}
  }
  :root {
    /** primary **/
    --ion-color-primary: ${colorDefaults.light.primary};
    --ion-color-primary-rgb: ${ConvertHexToRGBA(colorDefaults.light.primary)};
    --ion-color-primary-contrast: ${colorDefaults.light.primaryContrast};
    --ion-color-primary-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.primaryContrast)};
    --ion-color-primary-shade: #28ba62;
    --ion-color-primary-tint: #42d77d;
  
    /** secondary **/
    --ion-color-secondary: ${colorDefaults.light.secondary};
    --ion-color-secondary-rgb: ${ConvertHexToRGBA(colorDefaults.light.secondary)};
    --ion-color-secondary-contrast: ${colorDefaults.light.secondaryContrast};
    --ion-color-secondary-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.secondaryContrast)};
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;
  
    /** tertiary **/
    --ion-color-tertiary: ${colorDefaults.light.tertiary};
    --ion-color-tertiary-rgb: ${ConvertHexToRGBA(colorDefaults.light.tertiary)};
    --ion-color-tertiary-contrast: ${colorDefaults.light.tertiaryContrast};
    --ion-color-tertiary-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.tertiaryContrast)};
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;
  
    /** success **/
    --ion-color-success: ${colorDefaults.light.positive};
    --ion-color-success-rgb: ${ConvertHexToRGBA(colorDefaults.light.positive)};
    --ion-color-success-contrast: ${colorDefaults.light.positiveContrast};
    --ion-color-success-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.positiveContrast)};
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;
  
    /** warning **/
    --ion-color-warning: ${colorDefaults.light.warning};
    --ion-color-warning-rgb: ${ConvertHexToRGBA(colorDefaults.light.warning)};
    --ion-color-warning-contrast: ${colorDefaults.light.warningContrast};
    --ion-color-warning-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.warningContrast)};
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;
  
    /** danger **/
    --ion-color-danger: ${colorDefaults.light.negative};
    --ion-color-danger-rgb: ${ConvertHexToRGBA(colorDefaults.light.negative)};
    --ion-color-danger-contrast: ${colorDefaults.light.negativeContrast};
    --ion-color-danger-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.negativeContrast)};
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;
  
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
  
    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;
  
    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
  }
`;

export const IonicDarkTheme = `
body {
    /** primary **/
    --ion-color-primary: ${colorDefaults.light.primary};
    --ion-color-primary-rgb: ${ConvertHexToRGBA(colorDefaults.light.primary)};
    --ion-color-primary-contrast: ${colorDefaults.light.primaryContrast};
    --ion-color-primary-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.primaryContrast)};
    --ion-color-primary-shade: #28ba62;
    --ion-color-primary-tint: #42d77d;

    /** secondary **/
    --ion-color-secondary: ${colorDefaults.light.secondary};
    --ion-color-secondary-rgb: ${ConvertHexToRGBA(colorDefaults.light.secondary)};
    --ion-color-secondary-contrast: ${colorDefaults.light.secondaryContrast};
    --ion-color-secondary-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.secondaryContrast)};
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: ${colorDefaults.light.tertiary};
    --ion-color-tertiary-rgb: ${ConvertHexToRGBA(colorDefaults.light.tertiary)};
    --ion-color-tertiary-contrast: ${colorDefaults.light.tertiaryContrast};
    --ion-color-tertiary-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.tertiaryContrast)};
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: ${colorDefaults.light.positive};
    --ion-color-success-rgb: ${ConvertHexToRGBA(colorDefaults.light.positive)};
    --ion-color-success-contrast: ${colorDefaults.light.positiveContrast};
    --ion-color-success-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.positiveContrast)};
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: ${colorDefaults.light.warning};
    --ion-color-warning-rgb: ${ConvertHexToRGBA(colorDefaults.light.warning)};
    --ion-color-warning-contrast: ${colorDefaults.light.warningContrast};
    --ion-color-warning-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.warningContrast)};
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: ${colorDefaults.light.negative};
    --ion-color-danger-rgb: ${ConvertHexToRGBA(colorDefaults.light.negative)};
    --ion-color-danger-contrast: ${colorDefaults.light.negative};
    --ion-color-danger-contrast-rgb: ${ConvertHexToRGBA(colorDefaults.light.negativeContrast)};
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: ${colorDefaults.dark.background};
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .md body {
    --ion-background-color: ${colorDefaults.dark.background};
    --ion-background-color-rgb: ${ConvertHexToRGBA(colorDefaults.dark.background)};

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
`;

export const BoxShadowStyle = `
  border: 1px solid #ebebeb;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 12%);
`

export const BoxShadowStyleWithHover = `
  border: 1px solid #ebebeb;
  cursor: pointer;
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 12%);
  transition: border .15s linear, transform .15s linear, background-color .15s linear, box-shadow .15s linear, opacity .15s linear;

  &:hover {
    border: 1px solid rgba(136, 149, 162, 0.2);
    background-color: #fcfcfc;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 0%);
  }
`

export const theme = extendTheme({ colors })