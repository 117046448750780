import styled, { createGlobalStyle } from "styled-components"
import BREAKPOINTS from "../config/breakpoints"
import { Row } from "../components/Layout/Grid"
import { HEADING_FONT } from "../constants"
import { BaseIconStyle } from "../theme/input.core.styles"
import { StyledDropdown, StyledCalendar } from "../theme/input.styles"
import { ThemeOptions } from "../api/api-definitions"
import { Link } from "react-router-dom"

export const GlobalWidgetStyle = createGlobalStyle<{ widgetTheme?: ThemeOptions }>`
    body, #pageContent {
        ${props => props.widgetTheme?.backgroundColour ? `background-color: ${props.widgetTheme?.backgroundColour} !important;` : ''}
        ${props => props.widgetTheme?.backgroundColour ? `--ion-background-color: ${props.widgetTheme?.backgroundColour} !important;` : ''}
    }
`

export const BookingModuleContainer = styled.div<{ widgetTheme?: ThemeOptions }>`
    ${props => props.widgetTheme?.textColour ? `color: ${props.widgetTheme?.textColour} !important;` : ''}
    ${props => props.widgetTheme?.backgroundColour ? `background-color: ${props.widgetTheme?.backgroundColour} !important;` : ''}
    max-width: 36.15rem;
    margin: 0 auto;
    padding: 1rem;
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        padding: 0.7rem;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: #000 #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 5px;
    }

    *::-webkit-scrollbar-track {
        background: rgba(0,0,0,0);
    }

    *::-webkit-scrollbar-thumb {
        background-color: #000;
        border-radius: 5px;
    }

    .datepicker__event-day {
        position: relative;
        border-radius: 0.3rem;

        ::after {
            content: "*";
            color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
            position: absolute;
            left: 60%;
            top: -4px;
            width: 1rem;
            height: 1rem;
            font-weight: bold;
            font-size: 1rem;
            // border-top: 0.9rem solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
            // border-left: 0.9rem solid transparent;
        }
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        max-width: 79.5px;
        width: calc((100vw - 83px) / 7);
        line-height: 2rem;
        color: ${props => props.widgetTheme?.textColour || props.theme.primary};
        border: 1px solid transparent;
    }

    .react-datepicker__current-month {
        color: ${props => props.widgetTheme?.textColour || props.theme.primary};
    }

    .react-datepicker__day:hover {
        color: ${props => props.widgetTheme?.textColour || props.theme.primary};
        background-color: transparent;
        border: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    }

    .react-datepicker__day--disabled {
        opacity: 0.4;
    }

    .react-datepicker__navigation-icon {
        font-size: 40px;
        &::before {
            border-color: ${props => props.widgetTheme?.textColour || props.theme.primary};
        }
    }

    .react-datepicker__navigation {
        width: 80px;
        height: 80px;
    }

    .react-datepicker__day--outside-month {
        opacity: 0;
        pointer-events: none;
        touch-action: none;
    }

    .react-datepicker {
        background-color: transparent;
        margin-top: 0.5rem;
    }

    .react-datepicker__day--keyboard-selected {
        background-color: inherit;
        color: ${props => props.widgetTheme?.textColour || props.theme.primary};
    }

    .react-datepicker__header {
        background-color: transparent;
        color: ${props => props.widgetTheme?.textColour || props.theme.primary};
    }

    .react-datepicker__day--selected {
        background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props => props.widgetTheme?.buttonTextColour || props.theme.primaryContrast};

        &:hover {
            background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
            color: ${props => props.widgetTheme?.buttonTextColour || props.theme.primaryContrast};
            opacity: 0.7;
        }

        ::after {
            color: ${props => props.theme.primaryContrast} !important;
        }
    }

    ${BaseIconStyle} {
        background-color: transparent;
    }
`

export const Title = styled.h1<{ widgetTheme?: ThemeOptions }>`
    font-size: 1.2rem !important;
    padding: 0.5rem 0;
    padding-top: 0;
    margin-bottom: 0.5rem;
    // font-weight: bold;
    ${HEADING_FONT}
`

export const SubTitle = styled.h2<{ widgetTheme?: ThemeOptions }>`
    font-size: 1.1rem !important;
    padding: 0.5rem 0;
    padding-top: 0;
    margin-bottom: 0.5rem;
    // font-weight: bold;
    ${HEADING_FONT}
`

export const InfoForm = styled.div`

`

export const Button = styled.button<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 1rem;
    background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    cursor: pointer;
    border: none;

    &:disabled {
        opacity: 0.7;
    }
`

export const ExperienceOptionContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const ExperienceOption = styled.div<{ widgetTheme?: ThemeOptions; selected?: boolean; last?: boolean; }>`
    margin-top: 0.5rem;
    flex: 1 1 auto;
    padding: 0.5rem;
    border: 1px solid ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.2rem;
    margin-right: 0.5rem;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    width: calc(50% - 0.5rem);

    ${({ selected, widgetTheme, theme }) => selected ? `
        background-color: ${widgetTheme?.buttonColour || widgetTheme?.primaryColour || theme.primary};
        color: ${widgetTheme?.buttonTextColour || widgetTheme?.backgroundColour || theme.background};
    ` : `
        &:hover {
            opacity: 0.8;
        }
    `}

    :nth-child(even) {
        margin-right: 0;
        width: 50%;
    }

    ${props => props.last && `
        margin-right: 0;
        width: 50%;
    `}
`

export const SectionTitle = styled.div<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 0.5rem;
    border-bottom: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    color: inherit;
    margin-bottom: 1rem;
    font-weight: bold;
`

export const ButtonOutline = styled.button<{ widgetTheme?: ThemeOptions }>`
    width: 100%;
    padding: 1rem;
    border: 2px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 3px;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }
`

export const ButtonTime = styled.button<{ widgetTheme?: ThemeOptions, active?: boolean }>`
    width: 100%;
    padding: 0.5rem;
    border: 2px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.5rem;
    transition: all 0.4s ease;
    color: inherit;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    }

    ${props => props.active && `
        background-color: ${props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    `}
`

export const BookingQuickInfo = styled(Row)`
    text-align: center;
    .icon {
        margin-left: 0 !important;
    }
`

export const BookingQuickInfoTwo = styled.div`
    text-align: center;
    display: flow;
    margin-bottom: 1rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        display: flex;
        flex-wrap: wrap;
    }

    .icon {
        margin: 0 !important;
    }
`

export const BookingQuickInfoItem = styled.span<{ expand?: boolean }>`
    padding: 0 0.5rem;
    @media (max-width: ${BREAKPOINTS.mobileLarge}px) { 
        width: ${props => props.expand ? '100%' : '50%'};
        flex: none;
        display: inline-block;
    }
`

export const ButtonBack = styled.button<{ widgetTheme?: ThemeOptions }>`
    padding: 0.1rem 0.8rem 0.1rem 0.5rem;
    margin-bottom: 1rem;
    border: 2px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.5rem;
    transition: all 0.4s ease;

    &:hover {
        background-color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    }
`

export const ManageMyBookingLink = styled.a<{ widgetTheme?: ThemeOptions }>`
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 2px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 0.1rem;
    transition: all 0.4s ease;
    margin-top: 1rem;

    &:hover {
        background-color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
        color: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    }
`

export const YourDetails = styled.div`
    width: 100%;
    flex: 1 0 auto;

    input, textarea {
        color: #000;
        background: #FFF;
    }
`

export const FormRow = styled.div<{ widgetTheme?: ThemeOptions }>`
    display: flex;

    ${StyledDropdown}, ${StyledCalendar}, ${Button}, :focus {
        flex: 1;
        width: auto !important;
        height: 3rem !important;
        line-height: 1rem !important;
    }

    input, select, select:focus {
        width: 100%;
        background-color: transparent;
        border-color: #aeaeae !important;
        color: ${props => props.widgetTheme?.textColour || '#000'} !important;
    }

    ${BaseIconStyle} {
        border-color: #aeaeae !important;
        color: ${props => props.widgetTheme?.textColour || '#000'} !important;
    }

    select {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='${props => encodeURIComponent(props.widgetTheme?.textColour || '#000')}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    }

    option {
        color: ${props => props.widgetTheme?.textColour || '#000'};
        background-color: ${props => props.widgetTheme?.backgroundColour || props.theme.background};
        zoom: 1.2;
    }
`

export const TimeRow = styled(Row)`

`

export const PoweredBy = styled.div<{ widgetTheme?: ThemeOptions }>`
    background-color: ${props => props.widgetTheme?.buttonColour || props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.buttonTextColour || props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast};
    text-align: center;
    cursor: pointer;

    img {
        height: 0.7rem;
        display: inline;
        margin-left: 1rem;
    }
`

export const TransitionWrapper = styled.div`
    max-width: 100%;
    position: relative;
    min-height: 21rem;
    overflow: hidden;
`

export const Transition = styled.div<{ active: boolean, paymentActive?: boolean }>`
    width: 100%;
    transition: all 0.4s ease;
    display: flex;
    ${props => props.active ? 'margin-left: -100%;' : ''}
    ${props => props.paymentActive ? 'margin-left: -100%;' : ''}
`

export const ThankYou = styled.div`
    font-size: 1.6rem;
    margin: 1rem 0;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        font-size: 1.2rem;
        font-weight: bold;
    }
`

export const Checkmark = styled.span<{ widgetTheme?: ThemeOptions }>`
    border: 5px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-radius: 50%;
    padding: 1rem;
    font-size: 3rem;
    display: inline-block;
    width: 6.5rem;
    height: 6.5rem;

    @media (max-width: ${BREAKPOINTS.mobileLarge}px) {
        scale: 0.8;
    }
`

export const Logo = styled.span<{ widgetTheme?: ThemeOptions }>`
    display: inline-block;

    .st0, .st1 {
        fill: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast} !important;
    }
    .st2 {
        stroke: ${props => props.widgetTheme?.primaryContrastColour || props.theme.primaryContrast} !important;
    }

    svg {
        height: 1rem;
    }
`

export const BookingTabs = styled.div`
    display: flex;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
`

export const BookingTab = styled.div<{ widgetTheme?: ThemeOptions; selected?: boolean }>`
    flex: 1 0 auto;
    cursor: pointer;
    padding: 0.8rem 0;
    width: 50%;
    ${props => props.selected && `border-bottom: 4px solid ${props.widgetTheme?.primaryColour || props.theme.primary};`}
    ${props => !props.selected && `border-bottom: 1px solid ${props.widgetTheme?.primaryColour || props.theme.primary};`}

    &:hover {
        text-decoration: underline;
    }
`

export const InfoMessage = styled.div<{ widgetTheme?: ThemeOptions }>`
    border: 1px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    border-left: 11px solid ${props => props.widgetTheme?.primaryColour || props.theme.primary};
    color: ${props => props.widgetTheme?.textColour || props.theme.primary};
    padding: 0.8rem;

    .icon {
        margin: 0 0.2rem 0 0rem;
    }
`