export const CurrencyCodes = {
	SAR: "ر.س.‏",
	BGN: "лв.",
	EUR: "€",
	TWD: "NT$",
	CZK: "Kč",
	DKK: "kr.",
	USD: "$",
	ILS: "₪",
	HUF: "Ft",
	ISK: "kr.",
	JPY: "¥",
	KRW: "₩",
	NOK: "kr",
	PLN: "zł",
	BRL: "R$",
	RON: "lei",
	RUB: "₽",
	HRK: "kn",
	ALL: "Lek",
	SEK: "kr",
	THB: "฿",
	TRY: "₺",
	PKR: "Rs",
	IDR: "Rp",
	UAH: "₴",
	BYR: "р.",
	LTL: "Lt",
	TJS: "т.р.",
	IRR: "ريال",
	VND: "₫",
	AMD: "դր.",
	MKD: "ден.",
	ZAR: "R",
	GEL: "Lari",
	INR: "₹",
	MYR: "RM",
	KZT: "Т",
	KGS: "сом",
	KES: "S",
	TMT: "m.",
	MNT: "₮",
	CNY: "¥",
	GBP: "£",
	KHR: "៛",
	LAK: "₭",
	SYP: "ل.س.‏",
	LKR: "රු.",
	CAD: "$",
	ETB: "ETB",
	NPR: "रु",
	AFN: "؋",
	MVR: "ރ.",
	BOB: "$b",
	CLP: "$",
	NZD: "$",
	GTQ: "Q",
	RWF: "RWF",
	XOF: "XOF",
	IQD: "د.ع.‏",
	MXN: "$",
	BND: "$",
	BDT: "৳",
	EGP: "ج.م.‏",
	HKD: "HK$",
	AUD: "$",
	PEN: "S/.",
	LYD: "د.ل.‏",
	SGD: "$",
	MOP: "MOP",
	CRC: "₡",
	MAD: "د.م.‏",
	PAB: "B/.",
	TND: "د.ت.‏",
	DOP: "RD$",
	OMR: "ر.ع.‏",
	JMD: "J$",
	VEF: "Bs. F.",
	YER: "ر.ي.‏",
	COP: "$",
	BZD: "BZ$",
	JOD: "د.ا.‏",
	TTD: "TT$",
	ARS: "$",
	LBP: "ل.ل.‏",
	ZWL: "Z$",
	KWD: "د.ك.‏",
	AED: "د.إ.‏",
	UYU: "$U",
	BHD: "د.ب.‏",
	PYG: "Gs",
	QAR: "ر.ق.‏",
	HNL: "L.",
	AZN: "AZN"
};