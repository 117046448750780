import { BaseBusiness } from "../api/api-definitions";
import DatabaseService from "./DatabaseService";

export default class BusinessCacheService {
    private static readonly _key = 'business';
    private static readonly _indexKey = 'businessIdIndex';
    private static readonly _indexName = 'businessId';

    public static async GetBusiness(id: string) {
        return new Promise<BaseBusiness>((resolve, reject) => {
            DatabaseService.Database().then(db => {
                let transaction = db.transaction(this._key, "readonly");
                let objectStore = transaction.objectStore(this._key);
                let index = objectStore.index(this._indexKey);

                let getRequest = index.get(id);

                getRequest.onsuccess = function (event) {
                    let result = (event.target as any).result;
                    if (result?.response) {
                        resolve(JSON.parse(result.response) as BaseBusiness)
                    } else {
                        resolve(null)
                    }
                };
            });
        })
    }

    public static async UpsertBusiness(id: string, response: BaseBusiness) {
        DatabaseService.Database().then(db => {
            const transaction = db.transaction(this._key, "readwrite");
            const objectStore = transaction.objectStore(this._key);
            objectStore.put({
                [this._indexName]: id,
                response: JSON.stringify(response)
            }, id)
        });
    }
}