import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

export default class PaymentService {
    private static _stripeService: Stripe = null;

    public static async InitPaymentService(publicKey: string, accountId?: string): Promise<null> {
        if (accountId) {
            PaymentService._stripeService = await loadStripe(publicKey, {stripeAccount: accountId});
        } else {
            PaymentService._stripeService = await loadStripe(publicKey);
        }
        return;
    }

    public static get App(): Stripe {
        return PaymentService._stripeService;
    }
}