import React from "react";

export default function useOffline(): boolean {
  const [isOffline, setIsOffline] = React.useState<boolean>(false);

  function onOffline() {
    setIsOffline(true);
  }

  function onOnline() {
    setIsOffline(false);
  }

  React.useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
    };
  }, []);
  // @ts-ignore
  return window.offline || isOffline;
}
