import React, { useContext, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { BaseErrorMessage, BaseInputStyle, BaseLabelStyle } from '../../../theme/input.core.styles';
import { FormControlState } from '../../../controllers/easyFormConsumer';
import { useFormUpdate } from '../../../hooks/formState';
import { UseCommaInCurrency } from '../../../utils/currency-helper';
import { createUUID } from '../../../utils/data-helpers';
import i18n from '../../../utils/i18n';
import { cleanNumber, formatNumberToText, isValidValue } from '../../../utils/number-helper';
import { isNullOrWhitespace } from '../../../utils/text-helpers';
import { FormAttibuteContext, FormContext } from '../FormWrapper';
import { useForceUpdate } from '../../../hooks/useForceUpdate';

interface NumberInputProps {
  model?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, valid: boolean) => void;
  onBlur?: Function;
  onFocus?: Function;
  inputName?: string;
  value?: any;
  required?: boolean;
  label?: React.ReactNode;
  id?: string;
  decimalPlaces?: number;
  min?: number;
  max?: number;
  maxWarningMessage?: string;
  incrementOf?: number;
  disabled?: boolean;
  wholeNumbersOnly?: boolean;
  testingId?: string;
  defaultValue?: any;
  unlink?: boolean;
  validateOnLoad?: boolean;
  hasPlusMinus?: boolean;
  placeholder?: string;
  className?: string;
  hideValue?: boolean;
  readOnly?: boolean;
}

const DefaultNumericMax = 10000000000000000000;

const NumberInput = ({
  model,
  onChange,
  onBlur,
  onFocus,
  inputName,
  value,
  required,
  label,
  id,
  decimalPlaces,
  min,
  max = DefaultNumericMax,
  maxWarningMessage,
  incrementOf,
  disabled,
  wholeNumbersOnly,
  testingId,
  defaultValue,
  unlink,
  validateOnLoad,
  placeholder,
  hasPlusMinus,
  className,
  hideValue,
  readOnly
}: NumberInputProps) => {
  const context = useContext(FormContext);
  const uuid = useRef(createUUID());
  const formId = unlink || !context ? null : context;
  const nameToUse = inputName ? inputName : model;
  const [componentState, setComponentState] = useRecoilState(FormControlState(formId || uuid.current, model || inputName));
  const setComponentData = useFormUpdate(formId, model);
  const inputRef = useRef();
  const mounted = useRef<boolean>(false);
  const { error, internalValue } = componentState;
  const valueToUse = isNullOrWhitespace(internalValue) ? '' : internalValue;

  useEffect(() => {
    return () => {
      if (mounted.current) setComponentData(value, true);
    }
  }, [model])

  useEffect(() => {
    if (model && formId) {
      setComponentData(value, isValidValue(value) || !required);
    }

    let newValue = value;

    if (defaultValue) {
      newValue = defaultValue;
    }

    newValue = formatNumberToText(parseFloat(newValue), decimalPlaces);

    setComponentState({ internalValue: newValue, error });

    if (validateOnLoad) validate(newValue);

    mounted.current = true;
  }, [])

  useEffect(() => {
    if (mounted.current) {
      if (value === '-') {
        setComponentState({
          internalValue: value
        })
      } else if (!isNaN(value)) {
        if (document.activeElement !== inputRef.current) {
          setComponentState({ internalValue: formatNumberToText(parseFloat(value), decimalPlaces), error })
        } else {
          setComponentState({ internalValue: value, error })
        }
        if (model && formId) {
          const [valid] = validate(value)
          setComponentData(value, valid);
        }
      } else {
        setComponentState({ internalValue: value, error })
      }
    }
  }, [value]);

  useEffect(() => {
    if (mounted.current && !isNullOrWhitespace(internalValue)) {
      validate(internalValue)
    }
  }, [min, max])

  useEffect(() => {
    setValue({
      target: {
        value: value
      }
    })
  }, [required])

  const setValue = (e: any, cleanNumber: boolean = false, blur: boolean = false, setValidateState: boolean = false) => {
    let value: any = e.target.value;

    if (!isNullOrWhitespace(e.target.value)) {
      if (decimalPlaces) {
        const split = value.split(UseCommaInCurrency() ? ',' : '.');
        if (split[1] && split[1].length > decimalPlaces) return e.preventDefault();
      }

      if (value !== '-') {
        if (isNaN(parseFloat(value))) return;
        value = parseFloat(value);
      }

      if (cleanNumber && !isNullOrWhitespace(value) && value !== '-') {
        value = formatNumberToText(value, decimalPlaces);
      }
    }


    const [valid, newError, focused] = validate(value, blur, !setValidateState);

    if (model && formId) {
      setComponentData(value, valid);
    }

    if (onChange) {
      onChange(e, valid);
    }

    setComponentState({ internalValue: blur ? value : e.target.value, error: focused && !blur ? error : newError });
  }

  const validate = (value, blur: boolean = false, passthrough = false): [boolean, string, boolean] => {
    // Essentially we don't want the page jumping around when the user is typing
    // This checks if our input is focused, if so clear errors until they finish typing
    let focused = passthrough;

    if (document.activeElement === inputRef.current) {
      focused = isNullOrWhitespace(error) && !blur;
    }

    let preventStateSet = passthrough || focused;

    if (!isValidValue(value) && required) {
      const error = i18n('The value entered must be numeric');
      if (!preventStateSet) setComponentState({ error, internalValue: value });
      return [false, error, focused];
    } else if (isNaN(Number(value || ''))) {
      const error = i18n('The value entered must be numeric');
      if (!preventStateSet) setComponentState({ error, internalValue: value });
      return [false, error, focused];
    } else if (incrementOf && Number(value) % incrementOf !== 0) {
      const error = i18n('The number must be an increment of ') + String(incrementOf);
      if (!preventStateSet) setComponentState({ error, internalValue: value });
      return [false, error, focused];
    } else if (!isNullOrWhitespace(value) && (min || min === 0) && Number(value) < min) {
      const error = i18n('The number entered must be greater than or equal to ') + String(min);
      if (!preventStateSet) setComponentState({ error, internalValue: value });
      return [false, error, focused];
    } else if (!isNullOrWhitespace(value) && (max || max === 0) && Number(value) > max) {
      const error = !isNullOrWhitespace(maxWarningMessage) ? 
                    i18n(maxWarningMessage) + String(max) : 
                    i18n('The number entered must be less than or equal to ') + String(max);
      if (!preventStateSet) setComponentState({ error, internalValue: value });
      return [false, error, focused];
    } else {
      if (!preventStateSet) setComponentState({ error: null, internalValue: value });
      return [true, null, focused];
    }
  }

  return (
    <div className={className}>
      <FormAttibuteContext.Consumer>
        {attr => (
          <>
            {label &&
              <BaseLabelStyle className={required ? 'required' : ''} htmlFor={id ? id : nameToUse}>
                {label}
              </BaseLabelStyle>
            }

            {!hideValue &&
              <div style={{ display: 'flex' }}>
                {hasPlusMinus &&
                  <div className={'plusMinusToggle minus' + (min !== undefined && +valueToUse <= min ? ' disabled' : '')} onClick={() => (min === undefined || +valueToUse > min) ? setValue({ target: { value: +valueToUse - (incrementOf || 1) } }, false, false, true) : {}}>-</div>
                }
                <BaseInputStyle
                  type='number'
                  className={error ? `error ${hasPlusMinus && 'plusMinusToggleInput'}` : hasPlusMinus && 'plusMinusToggleInput'}
                  name={nameToUse}
                  id={id ? id : nameToUse}
                  value={valueToUse}
                  disabled={disabled || attr.disabled}
                  data-testid={testingId ? testingId : (id ? id : nameToUse)}
                  required={required}
                  ref={inputRef}
                  readOnly={readOnly}
                  onKeyDown={(e) => cleanNumber(e, wholeNumbersOnly)}
                  onChange={(e) => setValue(e)}
                  onFocus={(e) => onFocus ? onFocus(e) : null}
                  placeholder={placeholder}
                  onBlur={(e) => { setValue(e, true, true); if (onBlur) onBlur(); }} />
                {hasPlusMinus &&
                  <div className={'plusMinusToggle plus' + (max !== undefined && +valueToUse >= max ? ' disabled' : '')} onClick={() => (max === undefined || +valueToUse < max) ? setValue({ target: { value: +valueToUse + (incrementOf || 1) } }, false, false, true) : {}}>+</div>
                }
              </div>
            }

            {hideValue && <BaseInputStyle readOnly disabled />}

            {!hideValue && error &&
              <BaseErrorMessage className=''>
                {error}
              </BaseErrorMessage>
            }
          </>
        )}
      </FormAttibuteContext.Consumer>
    </div>
  );
};

export default NumberInput;