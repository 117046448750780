import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useHistory } from 'react-router';
import { atom, useRecoilState } from 'recoil';
import { ApiService } from '../../api/api-connectors';
import { BaseAppUser, UserAccountDetails } from '../../api/api-definitions';
import { Constants } from '../../constants';
import { createUUID } from '../../utils/data-helpers';
import BusinessService from '../../services/BusinessService';
import BusinessListService from '../../services/BusinessListService';
import LogRocket from 'logrocket';
import useOffline from '../../hooks/useOffline';

interface ComponentProps {
  children: any;
}

export interface GlobalState {
  isLoggedIn?: boolean;
  loginLoaded?: boolean;
  data?: UserAccountDetails;
  googleData?: any;
  jwt?: string;
  hasErrored?: boolean;
}

export const LoginState = atom<GlobalState>({
  key: 'loginState',
  default: {
    data: null
  },
});

function getEmptyData(): UserAccountDetails {
  const user: UserAccountDetails  = { userName: '', imageUrl: '' };
  return user;
}

const AuthenticationWrapper = ({ children }: ComponentProps) => {
  const offline = useOffline();
  const [loginValue, setLoginValue] = useRecoilState(LoginState);
  const [loaded, setLoaded] = useState(false);
  const sessionGuid = sessionStorage.getItem('sessionGuid');
  if (!sessionGuid) sessionStorage.setItem('sessionGuid', createUUID())
  const navigate = useHistory();
  
  useEffect(() => {
    if (offline) {
      loadOfflineData()
    } else {
      checkLoginStatus();
      // @ts-ignore
      window.refreshLogin = checkLoginStatus;
    }
  }, [offline]);

  const loadOfflineData = () => {
    const sessionValue = sessionStorage.getItem('loginValue');
      if (sessionValue) {
        setLoaded(true)
        setLoginValue(JSON.parse(sessionValue))
        // @ts-ignore
        window.offline = true;
      } else {
        setLoginValue({ isLoggedIn: false, loginLoaded: true, data: getEmptyData(), googleData: null, jwt: null, hasErrored: true });
        setLoaded(true)
      }
  }
  
  const checkLoginStatus = () => {
    const googleSession = sessionStorage.getItem('googleLogin');
    const googleData = googleSession ? JSON.parse(googleSession).profileObj : null;
    const googleJwt = googleSession ? JSON.parse(googleSession).tokenId : null;
    
    ApiService.account.IsLoggedIn__GET().then((result: any) => {
      if (result.success) {
        ApiService.account.UserInfo__GET().then((data: UserAccountDetails) => {
          // LogRocket.identify(data.userName, {
          //   name: data.userName,
          //   email: data.email,
          
          //   // Add your own custom user variables here, ie:
          //   subscriptionType: 'pro'
          // });
          sessionStorage.setItem('loginValue', JSON.stringify({ data, loginLoaded: true, isLoggedIn: true, googleData, jwt: googleJwt }))
          setLoginValue({ data, loginLoaded: true, isLoggedIn: true, googleData, jwt: googleJwt })
          setLoaded(true)
        }).catch(() => {
          setLoginValue({ data: getEmptyData(), loginLoaded: true, isLoggedIn: false, googleData, jwt: googleJwt, hasErrored: true })
          setLoaded(true)
        })
      } else {
        setLoginValue({ isLoggedIn: false, loginLoaded: true, data: getEmptyData(), googleData, jwt: googleJwt });
        setLoaded(true)
      }
    }).catch(() => {
      loadOfflineData()
    })

    // @ts-ignore
    window.logout = logout;
  }

  const { signOut } = useGoogleLogout({
    clientId: Constants.googleAuthApiKey
  })

  const logout = () => {
    signOut();
    sessionStorage.removeItem('googleLogin');
    const newDetails = cloneDeep(loginValue);
    newDetails.jwt = null;
    newDetails.googleData = null;
    setLoginValue(newDetails);
    BusinessService.Clear();
    BusinessListService.Clear();
    ApiService.account.Logout__POST().then(() => {
      navigate.push('/');
    })
  }

  if (loaded && !loginValue.isLoggedIn && loginValue.jwt) {
    const newDetails = cloneDeep(loginValue);
    newDetails.jwt = null;
    newDetails.googleData = null;
    setLoginValue(newDetails);
    sessionStorage.removeItem('googleLogin');
  }

  return children;
};

export default AuthenticationWrapper;

