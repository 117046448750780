import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { isNullOrWhitespace } from './utils/text-helpers';
import * as Sentry from "@sentry/react";
import { replayIntegration } from '@sentry/react';


if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://e33df79c945bd8be291730a3d66c4f6a@o4506673859919872.ingest.sentry.io/4506673862213632",
    release: "DishForagerDashboard@1.1.1",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

const applicationRootVar = process.env.REACT_APP_APPLICATION_ROOT || process.env.react_app_application_root;

if (isNullOrWhitespace(applicationRootVar) || applicationRootVar.trimEnd() === 'web') {
  const Component = require('./App').default;
  root.render(
    <Component />
  );
}

if (applicationRootVar.trimEnd() === 'dashboard') {
  const Component = require('./DashboardApp').default;
  root.render(
    <Component />
  );
}

if (applicationRootVar.trimEnd() === 'bookings') {
  const Component = require('./BookingApp').default;
  root.render(
    <Component />
  );
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
