import { useStripe, useElements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BoxShadowStyle } from '../../theme';
import CoreButton from '../Forms/Button';
import { ErrorMessage } from '../Forms/Messaging';
import Loader from '../Layout/Loader';
import { H3 } from '../Typography/Headings';
import { ThemeOptions } from '../../api/api-definitions';

const CardWrapper = styled.div`
`

const WidgetButton = styled.button<{ primary: string, primaryContrast: string }>`
    width: 100%;
    padding: 1rem;
    background-color: ${props => props.primary || props.theme.primary};
    color: ${props => props.primaryContrast || props.theme.primaryContrast};
    margin-bottom: 1rem;
    border-radius: 3px;
    cursor: pointer;
    border: none;

    &:disabled {
        opacity: 0.7;
    }
`

interface Props {
  payNow: boolean;
  widget?: boolean;
  widgetTheme?: ThemeOptions;
  returnUrl?: string;
}

const CheckoutForm = ({ payNow, widget, widgetTheme, returnUrl = window.location.href }: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true)

    if (payNow) {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });
      if (result.error) {
        setLoading(false)
        setError(result.error.message)
      }
    } else {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });
      if (result.error) {
        setLoading(false)
        setError(result.error.message)
      }
    }
  };

  return (
    <form>
      <>
        <CardWrapper>
          <H3>Card details</H3>
          <PaymentElement />
          {error &&
            <>
              <br />
              <ErrorMessage>{error}</ErrorMessage>
              <br />
            </>
          }
        </CardWrapper>
        <br />
        {widget &&
          <WidgetButton primary={widgetTheme?.primaryColour || ''} primaryContrast={widgetTheme?.primaryContrastColour || ''} onClick={handleSubmit} disabled={!stripe || !elements || loading}>
            {payNow ? 'Complete payment' : 'Submit'}
          </WidgetButton>
        }
        {!widget &&
          <CoreButton type='primary' full onClick={handleSubmit} disabled={!stripe || !elements || loading}>
            {payNow ? 'Complete payment' : 'Submit'}
          </CoreButton>
        }
      </>
    </form>
  );
};

export default CheckoutForm;